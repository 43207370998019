import React from 'react';
import {
    ButtonContainer,
    ControlsContainer,
    HeaderComponent,
} from 'components/customer/RoomPlanner/components/Menu/Header';
import {
    MenuItem,
    useMenuContext,
} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {Icon} from 'shared/helpers';
import Room from 'components/customer/Room/Room';

export const Materials = () => {
    const {handleClick} = useMenuContext();

    return (
        <div>
            <HeaderComponent>
                <ControlsContainer>
                    <div />

                    <ButtonContainer onClick={handleClick(MenuItem.MATERIALS)}>
                        <Icon iconName="Button-Cancel.svg" />
                    </ButtonContainer>
                </ControlsContainer>
            </HeaderComponent>

            <br />
            <Room tabIndex={2} isMiniEditor={true} showTabNavs={[2, 3]} />
        </div>
    );
};
