import React from 'react';
import {
    ButtonContainer,
    ControlsContainer,
    HeaderComponent,
} from 'components/customer/RoomPlanner/components/Menu/Header';
import {
    MenuItem,
    useMenuContext,
} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {Icon} from 'shared/helpers';
import Room from 'components/customer/Room/Room';
import styled from 'styled-components';

export const Hardware = () => {
    const {handleClick} = useMenuContext();

    return (
        <div>
            <HeaderComponent>
                <ControlsContainer>
                    <Header>Hardware selections</Header>

                    <ButtonContainer
                        onClick={handleClick(MenuItem.HARDWARE_SELECTIONS)}>
                        <Icon iconName="Button-Cancel.svg" />
                    </ButtonContainer>
                </ControlsContainer>
            </HeaderComponent>

            <br />
            <Room tabIndex={1} isMiniEditor={true} showTabNavs={[]} />
        </div>
    );
};

const Header = styled.div`
    color: ${({theme}) => theme.colors.primary.main};
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 4px;
`;
