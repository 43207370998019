import {useLazyGetProductDataQuery} from 'components/customer/Product/store/productApi';
import {useJobContext} from 'contexts';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {getQuickProducts} from 'service';
import {PartialJob, Job} from 'shared/types/PartialJob';

export const useProductName = () => {
    const [name, setName] = useState('');
    const [productNumber, setProductNumber] = useState('');
    const [searchParams] = useSearchParams();
    const {roomId} = useParams();

    const productId = searchParams.get('product');
    const cabinetId = searchParams.get('cabinetId');

    const {job} = useJobContext() as PartialJob;

    const [getProductData] = useLazyGetProductDataQuery();

    const getProductName = useCallback(async (productId: string) => {
        const products = await getQuickProducts();

        const product = products.find(
            (product) => product.id == Number(productId)
        );

        if (product) {
            setName(product.text);
        }
    }, []);

    const getProductNumber = useCallback(
        async (
            productId: string,
            cabinetId: string,
            roomId: string,
            job: Job
        ) => {
            const {data: productData} = await getProductData(
                {
                    cabinetId: Number(cabinetId),
                    roomId: Number(roomId),
                    cabinetType: Number(productId),
                },
                true
            );

            if (productData) {
                const roomCabinetNumber = productData.room_cab_number;

                const room = job?.rooms?.find(
                    (room) => room.id == Number(roomId)
                );

                if (room) {
                    setProductNumber(`${room.roomNumber}-${roomCabinetNumber}`);
                }
            }
        },
        []
    );

    useEffect(() => {
        if (productId) void getProductName(productId);
        else setName('');
    }, [productId]);

    useEffect(() => {
        if (productId && cabinetId && roomId && job) {
            void getProductNumber(productId, cabinetId, roomId, job);
        } else {
            setProductNumber('');
        }
    }, [cabinetId, productId, roomId, job]);

    return {
        name,
        productNumber,
    };
};
