import React, {
    MouseEventHandler,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Event, pubSub} from 'components/customer/RoomPlanner/helper/PubSub';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {setPreviewPosition} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {useAppDispatch} from 'store/customer';

export enum MenuItem {
    ADD_PRODUCT = 'ADD_PRODUCT',
    HARDWARE_SELECTIONS = 'HARDWARE_SELECTIONS',
    MATERIALS = 'MATERIALS',
    EDIT_ROOM = 'EDIT_ROOM',
    EXIT_ROOM_PLANNER = 'EXIT_ROOM_PLANNER',
}

export enum PreviewType {
    PREVIEW_3D,
    PREVIEW_EDGE,
    PREVIEW_DOOR,
    PREVIEW_DRAWER,
    PREVIEW_SHELVES,
}

interface MenuContext {
    selectedMenu?: string;
    handleClick?: (event: string) => MouseEventHandler<HTMLElement>;
    hideMenu?: () => void;
    preview?: React.ReactNode;
    previewDoor?: React.ReactNode;
    previewEdge?: React.ReactNode;
    previewDrawer?: React.ReactNode;
    previewShelves?: React.ReactNode;
    previewType?: PreviewType;
}

const MenuContext = createContext<MenuContext>({});

const useMenuContext = () => useContext(MenuContext);

const MenuProvider = ({children}: PropsWithChildren<object>) => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const {jobId, roomId} = useParams();
    const navigate = useNavigate();
    const {setIsRoomPlanner} = useJobContext() as PartialJob;

    const [selectedMenu, setSelectedMenu] = useState<string>('');
    const [preview, setPreview] = useState<React.ReactNode>();
    const [previewEdge, setPreviewEdge] = useState<React.ReactNode>();
    const [previewDoor, setPreviewDoor] = useState<React.ReactNode>();
    const [previewDrawer, setPreviewDrawer] = useState<React.ReactNode>();
    const [previewShelves, setPreviewShelves] = useState<React.ReactNode>();
    const [previewType, setPreviewType] = useState<PreviewType>(
        PreviewType.PREVIEW_3D
    );

    const cabinetIdReference = useRef<string | null>(null);

    const handleClick = useCallback(
        (event: string) => () => {
            switch (event) {
                case MenuItem.EXIT_ROOM_PLANNER:
                    void navigate(`/v2/job/${jobId}/room/${roomId}/dashboard`);
                    break;
                case MenuItem.EDIT_ROOM:
                    dispatch(setPreviewPosition('TOP'));
                    break;
                default:
                    setSelectedMenu((selectedMenu) => {
                        if (selectedMenu == event) {
                            return '';
                        }

                        return event;
                    });
                    break;
            }
        },
        [navigate, jobId, roomId]
    );

    const hideMenu = () => {
        setSelectedMenu('');
    };

    useEffect(() => {
        const previewHandler = (data: React.ReactNode) => {
            setPreview(data);
        };

        const previewHandlerDoor = (data: React.ReactNode) => {
            setPreviewDoor(data);
        };

        const previewHandlerEdge = (data: React.ReactNode) => {
            setPreviewEdge(data);
        };

        const previewHandlerDrawer = (data: React.ReactNode) => {
            setPreviewDrawer(data);
        };

        const previewHandlerShelves = (data: React.ReactNode) => {
            setPreviewShelves(data);
        };

        const previewTypeHandler = (data: PreviewType) => {
            setPreviewType(data);
        };

        pubSub.subscribe(Event.ROOM_PLANNER_3D_PREVIEW, previewHandler);
        pubSub.subscribe(Event.ROOM_PLANNER_DOOR_PREVIEW, previewHandlerDoor);
        pubSub.subscribe(Event.ROOM_PLANNER_EDGE_PREVIEW, previewHandlerEdge);
        pubSub.subscribe(
            Event.ROOM_PLANNER_DRWAWER_PREVIEW,
            previewHandlerDrawer
        );
        pubSub.subscribe(
            Event.ROOM_PLANNER_SHELVES_PREVIEW,
            previewHandlerShelves
        );
        pubSub.subscribe(Event.ROOM_PLANNER_PREVIEW_TYPE, previewTypeHandler);

        return () => {
            pubSub.unsubscribe(Event.ROOM_PLANNER_3D_PREVIEW, previewHandler);
            pubSub.unsubscribe(
                Event.ROOM_PLANNER_DOOR_PREVIEW,
                previewHandlerDoor
            );
            pubSub.unsubscribe(
                Event.ROOM_PLANNER_EDGE_PREVIEW,
                previewHandlerEdge
            );
            pubSub.unsubscribe(
                Event.ROOM_PLANNER_DRWAWER_PREVIEW,
                previewHandlerDrawer
            );
            pubSub.unsubscribe(
                Event.ROOM_PLANNER_SHELVES_PREVIEW,
                previewHandlerShelves
            );
            pubSub.unsubscribe(
                Event.ROOM_PLANNER_PREVIEW_TYPE,
                previewTypeHandler
            );
        };
    }, []);

    useEffect(() => {
        setIsRoomPlanner(true);

        return () => {
            setIsRoomPlanner(false);
        };
    }, []);

    useEffect(() => {
        const cabinetId = searchParams.get('cabinetId');

        const isProductEdit = !!cabinetId;

        if (isProductEdit && selectedMenu !== MenuItem.ADD_PRODUCT) {
            if (cabinetIdReference.current !== cabinetId) {
                cabinetIdReference.current = cabinetId;
                setSelectedMenu(MenuItem.ADD_PRODUCT);
            }
        }
    }, [searchParams, selectedMenu]);

    return (
        <MenuContext.Provider
            value={{
                selectedMenu,
                handleClick,
                hideMenu,
                preview,
                previewEdge,
                previewDoor,
                previewDrawer,
                previewShelves,
                previewType,
            }}>
            {children}
        </MenuContext.Provider>
    );
};

export {MenuContext, useMenuContext, MenuProvider};
