import React, {PropsWithChildren, useState, useCallback} from 'react';
import styled from 'styled-components';
import {getColoredImage, Icon} from 'shared/helpers';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    getActiveGroup,
    setIsRoomPlannerEditing,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import * as THREE from 'three';
import {useSearchParams} from 'react-router-dom';
import {Image} from 'react-bootstrap';
import {DrawerClosed, DrawerOpen} from 'assets';
import {OverlayTrigger} from 'shared';
import {
    invalidateProductAndCost,
    useDeleteCabinetMutation,
} from 'components/customer/Product/store/productApi';
import useAutoSave from 'components/customer/RoomPlanner/lib/useAutoSave';
import {useJobContext} from 'contexts';
import {invalidateTotalProductCount} from 'components/customer/Job/store/jobApi';
import {MenuItem, useMenuContext} from '../Menu/MenuContext';

/*
   Applied Component Composition via Static Properties
*/

/* eslint-disable */

const triggerEvents = (
    group: THREE.Group<THREE.Object3DEventMap>,
    eventName: string,
    params: unknown
) => {
    group.traverse((child) => {
        if (typeof child.userData[eventName] === 'function') {
            child.userData[eventName](params);
        }
    });
};

const FloatingMenuOption = ({children}: PropsWithChildren) => children;

const Edit = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeGroup = useAppSelector(getActiveGroup);
    const {handleClick} = useMenuContext();

    const dispatch = useAppDispatch();

    const handleEditClick = useCallback(() => {
        if (activeGroup) {
            searchParams.set(
                'cabinetId',
                activeGroup.userData.values.job_cabinet_id?.toString() || ''
            );
            searchParams.set(
                'product',
                activeGroup.userData.values.type?.toString() || ''
            );
            setSearchParams(searchParams);
            dispatch(setIsRoomPlannerEditing(true));
            if (handleClick) {
                handleClick(MenuItem.ADD_PRODUCT);
            }
        }
    }, [activeGroup, searchParams, handleClick]);

    return (
        <OverlayTrigger placement="top" overlay={<strong>Edit</strong>}>
            <MenuButton onClick={handleEditClick}>
                <Icon
                    className="icon_button"
                    color="#b2bec3"
                    iconName={'Button-Edit-Primary.svg'}
                />
            </MenuButton>
        </OverlayTrigger>
    );
};

const Copy = () => {
    const activeGroup = useAppSelector(getActiveGroup);

    const handleCopy = () => {
        activeGroup.userData.duplicateCabinet();
    };

    return (
        <OverlayTrigger placement="top" overlay={<strong>Duplicate</strong>}>
            <MenuButton onClick={handleCopy}>
                <Icon
                    className="icon_button"
                    color="#b2bec3"
                    iconName="Options-Copy.svg"
                />
            </MenuButton>
        </OverlayTrigger>
    );
};

const DoorToggle = () => {
    const activeGroup = useAppSelector(getActiveGroup);

    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    const toggleDoor = useCallback(() => {
        triggerEvents(activeGroup, 'toggleDoor', {
            doorGap: activeGroup.userData.values.cabinet_door_gap,
        });

        setIsOpen((prev) => !prev);
    }, [dispatch, activeGroup]);

    return (
        <OverlayTrigger
            placement="top"
            overlay={<strong>{isOpen ? 'Close door' : 'Open Door'}</strong>}>
            <StyledImageAction
                className="icon_button"
                style={{backgroundColor: 'transparent', height: '20px'}}
                src={getColoredImage(
                    isOpen ? DrawerClosed : DrawerOpen,
                    '#b2bec3',
                    '#ffffff'
                )}
                onClick={toggleDoor}
            />
        </OverlayTrigger>
    );
};

const RotateLeft = () => {
    const activeGroup = useAppSelector(getActiveGroup);
    const {debouncedSave} = useAutoSave();

    const handleRotate = useCallback(() => {
        activeGroup.userData.rotateLeft();
        debouncedSave(activeGroup);
    }, [activeGroup, debouncedSave]);

    return (
        <OverlayTrigger placement="top" overlay={<strong>Rotate left</strong>}>
            <MenuButton onClick={handleRotate}>
                <Icon
                    className="icon_button"
                    color="#b2bec3"
                    iconName={'Button-Rotate-Anti-Clockwise.svg'}
                />
            </MenuButton>
        </OverlayTrigger>
    );
};

const RotateRight = () => {
    const activeGroup = useAppSelector(getActiveGroup);
    const {debouncedSave} = useAutoSave();

    const handleRotate = useCallback(() => {
        activeGroup.userData.rotateRight();
        debouncedSave(activeGroup);
    }, [activeGroup, debouncedSave]);

    return (
        <OverlayTrigger placement="top" overlay={<strong>Rotate right</strong>}>
            <MenuButton onClick={handleRotate}>
                <Icon
                    className="icon_button"
                    color="#b2bec3"
                    iconName={'Button-Rotate-Clockwise.svg'}
                />
            </MenuButton>
        </OverlayTrigger>
    );
};

const Delete = () => {
    const activeGroup = useAppSelector(getActiveGroup);

    const [deleteProduct] = useDeleteCabinetMutation();

    const dispatch = useAppDispatch();
    const {setIsPageDirty} = useJobContext();

    // changed delete behavior
    const handleDeleteClick = useCallback(() => {
        deleteProduct({cabinetId: activeGroup.userData.values.id});
        activeGroup.userData.deleteCabinet();
        setIsPageDirty([]);
        dispatch(invalidateProductAndCost());
        dispatch(invalidateTotalProductCount());
    }, []);

    return (
        <OverlayTrigger placement="top" overlay={<strong>Delete</strong>}>
            <MenuButton onClick={handleDeleteClick}>
                <Icon
                    className="icon_button"
                    color="#b2bec3"
                    iconName="Layout-Delete.svg"
                />
            </MenuButton>
        </OverlayTrigger>
    );
};

const StyledImageAction = styled(Image)<{$expanded?: boolean}>`
    max-height: ${({$expanded}) => ($expanded ? '50px' : '36px')};
    cursor: pointer;
    border: 2px solid transparent;
    margin: 2px;
`;

const MenuButton = styled.button`
    background-color: #1e1e1e;
    width: 30px;
    height: 36px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color 0.2s ease-in-out, transform 0.1s;

    &:hover {
        background-color: #333;
    }

    &:active {
        background-color: #444;
        transform: scale(0.95);
    }

    svg {
        width: 18px;
        height: 18px;
        color: #f1f1f1;
    }
`;

FloatingMenuOption.Edit = Edit;
FloatingMenuOption.Copy = Copy;
FloatingMenuOption.DoorToggle = DoorToggle;
FloatingMenuOption.RotateLeft = RotateLeft;
FloatingMenuOption.RotateRight = RotateRight;
FloatingMenuOption.Delete = Delete;

export default FloatingMenuOption;
