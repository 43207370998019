import {useRef, MutableRefObject, useState, useEffect} from 'react';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {debounce} from 'lodash';
import {
    RoomPlannerProduct,
    RoomType,
} from 'components/customer/RoomPlanner/types';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    setActiveGroup,
    setIsDragging,
    getShowArrows,
    setSelectedProductValue,
    setIsRoomPlannerEditing,
    getisRoomPlannerEditing,
} from '../store/roomPlannerSlice';
import useCabinetSelect from './useCabinetSelect';
import useAutoSave from 'components/customer/RoomPlanner/lib/useAutoSave';
import ObjectNameMap from '../constants/ObjectNameMap';
import {useSearchParams} from 'react-router-dom';
import {useMenuContext} from '../components/Menu/MenuContext';
import useCabinetOverlap from './useCabinetOverlaps';

export type ProductEventNames =
    | 'onSnap'
    | 'onOverlap'
    | 'onCabinetSelect'
    | 'onClick'
    | 'toggleDoor'
    | 'onToggleWireframe';

/* eslint-disable */
const useProductEvents = (
    renderer: MutableRefObject<THREE.WebGLRenderer>,
    mountRef: MutableRefObject<HTMLDivElement>,
    setShowArrows: React.Dispatch<React.SetStateAction<boolean>>,
    setDragPosition: React.Dispatch<React.SetStateAction<THREE.Vector3>>,
    productsRef: MutableRefObject<THREE.Group<THREE.Object3DEventMap>[]>
) => {
    const [selectedCabinetGroup, setSelectedCabinetGroup] =
        useState<THREE.Group<THREE.Object3DEventMap>>();

    const {selectCabinet} = useCabinetSelect();

    const dispatch = useAppDispatch();

    const isRoomPlannerEditing = useAppSelector(getisRoomPlannerEditing);
    const [searchParams, setSearchParams] = useSearchParams();
    const {hideMenu} = useMenuContext();

    const {overlapValidator} = useCabinetOverlap();

    const eventHandlersRef = useRef([]);
    const intersectionsRef = useRef<
        {
            distance: number;
            group: THREE.Group<THREE.Object3DEventMap>;
            id: number;
        }[]
    >([]);
    const {debouncedSave} = useAutoSave();
    const showArrows = useAppSelector(getShowArrows);

    const triggerEvents = (
        group: THREE.Group<THREE.Object3DEventMap>,
        eventName: ProductEventNames,
        params: any
    ) => {
        group.traverse((child) => {
            if (typeof child.userData[eventName] === 'function') {
                child.userData[eventName](params);
            }
        });
    };

    const getGroupWidth = (group: THREE.Group<THREE.Object3DEventMap>) => {
        const boundingBox = new THREE.Box3().setFromObject(group);

        const width = boundingBox.max.x - boundingBox.min.x;
        const height = boundingBox.max.y - boundingBox.min.y;
        const depth = boundingBox.max.z - boundingBox.min.z;

        return {width, height, depth};
    };

    const getDirection = (rotation: number) => {
        const normalizedRotation =
            ((rotation % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);

        if (Math.abs(normalizedRotation) < 0.1) return 'normal';
        if (Math.abs(normalizedRotation - Math.PI / 2) < 0.1) return 'right';
        if (Math.abs(normalizedRotation - Math.PI) < 0.1) return 'back';
        if (Math.abs(normalizedRotation - (3 * Math.PI) / 2) < 0.1)
            return 'left';
        return 'normal';
    };

    const attachEvents = ({
        scene,
        cabinetGroup,
        scale,
        camera,
        controls,
        product,
        computedWidth,
        positionY,
        roomWidth,
        roomDepth,
        roomType,
        roomLeftDepth,
        roomRightDepth,
        roomLeftWidth,
        roomRightWidth,
    }: {
        scene: THREE.Scene;
        cabinetGroup: THREE.Group<THREE.Object3DEventMap>;
        scale: number;
        camera: MutableRefObject<THREE.OrthographicCamera>;
        controls: MutableRefObject<OrbitControls>;
        product: RoomPlannerProduct;
        computedWidth?: number;
        positionY: number;
        roomWidth: number;
        roomDepth: number;
        roomType: RoomType;
        roomLeftDepth: number;
        roomRightDepth: number;
        roomLeftWidth: number;
        roomRightWidth: number;
    }) => {
        const {width, height, depth} = getGroupWidth(cabinetGroup);

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        const variables = JSON.parse(
            cabinetGroup.userData.values.template_3d[0].attributes.variables
        );

        let isDragging = false;
        let dragOffset = new THREE.Vector3();

        const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

        const findParentWithId = (
            object: THREE.Object3D<THREE.Object3DEventMap>
        ) => {
            while (object) {
                if (object.userData.id) return object;
                object = object.parent;
            }
            return null;
        };

        const onMouseDown = (event: MouseEvent) => {
            if (event.button !== 0) return;

            const dimension = mountRef.current.getBoundingClientRect();
            mouse.x =
                ((event.clientX - dimension.left) / dimension.width) * 2 - 1;
            mouse.y =
                (-(event.clientY - dimension.top) / dimension.height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera.current);

            const intersects = raycaster.intersectObject(cabinetGroup);

            if (
                intersects.length > 0 &&
                findParentWithId(intersects[0].object)?.userData.id ==
                    cabinetGroup.userData.id
            ) {
                isDragging = true;
                dispatch(setIsDragging(true));

                if (cabinetGroup.userData.selected) {
                    controls.current.enabled = false;
                }

                const intersectPoint = intersects[0].point;

                /* 
                    Dragging behavior will vary based on what type of placement the product has. 
                    On our case, only upper and regular products
                */
                if (!variables?.isUpperProduct) {
                    plane.setFromNormalAndCoplanarPoint(
                        new THREE.Vector3(0, 1, 0),
                        intersectPoint
                    );
                } else {
                    plane.setFromNormalAndCoplanarPoint(
                        new THREE.Vector3(0, 0, 2), // Normal points outward from the wall
                        intersectPoint
                    );
                }

                dragOffset.copy(intersectPoint).sub(cabinetGroup.position);
            }
        };

        const onMouseMove = (event: MouseEvent) => {
            if (!isDragging) return;
            if (!cabinetGroup.userData.selected) return;

            dispatch(setActiveGroup(cabinetGroup));
            setSelectedCabinetGroup(cabinetGroup);

            const dimension = mountRef.current.getBoundingClientRect();
            mouse.x =
                ((event.clientX - dimension.left) / dimension.width) * 2 - 1;
            mouse.y =
                (-(event.clientY - dimension.top) / dimension.height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera.current);

            const intersectionPoint = new THREE.Vector3();
            raycaster.ray.intersectPlane(plane, intersectionPoint);

            let dragPosition = intersectionPoint.sub(dragOffset);
            setDragPosition(dragPosition);

            const targetPos = dragPosition.clone();
            let snapPosition = targetPos.clone();
            let snapped = false;
            const snapDistance = 120;
            const {width: latestWidth, depth: latestDepth} =
                getGroupWidth(cabinetGroup);
            let isSnappedBackward = false;

            const wall = scene.children.find(
                (ch) =>
                    ch.name === ObjectNameMap.rectangularWall ||
                    ch.name === ObjectNameMap.lShapeWall
            ) as THREE.Mesh<THREE.ExtrudeGeometry, THREE.MeshStandardMaterial>;

            productsRef.current?.forEach((otherProduct) => {
                if (otherProduct.userData.id == cabinetGroup.userData.id)
                    return;
                const box = new THREE.Box3().setFromObject(otherProduct);
                const size = new THREE.Vector3();
                box.getSize(size);

                const directions = [
                    new THREE.Vector3(1, 0, 0), // Right
                    new THREE.Vector3(-1, 0, 0), // Left
                    new THREE.Vector3(0, 0, 1), // Forward
                    new THREE.Vector3(0, 0, -1), // Backward
                ];

                directions.forEach((dir, index) => {
                    const axis = dir.clone().multiply(size);
                    const proposedPos = otherProduct.position.clone().add(axis);
                    const distance = proposedPos.distanceTo(targetPos);
                    const selectedCabinetDirection = getDirection(
                        cabinetGroup.rotation.z
                    );
                    const otherCabinetDirection = getDirection(
                        otherProduct.rotation.z
                    );

                    if (
                        distance < snapDistance &&
                        selectedCabinetDirection === otherCabinetDirection
                    ) {
                        const snappedProduct = getGroupWidth(otherProduct);
                        if (index === 0 || index === 1) {
                            proposedPos.z =
                                proposedPos.z -
                                (snappedProduct.depth - latestDepth);
                        }

                        if (index === 1) {
                            proposedPos.x =
                                proposedPos.x - width + snappedProduct.width;
                        }

                        if (index === 2) {
                            const relativeX =
                                targetPos.x - otherProduct.position.x;

                            const isRightSide = relativeX > 0;

                            proposedPos.z =
                                proposedPos.z +
                                latestDepth -
                                snappedProduct.depth;

                            if (isRightSide) {
                                proposedPos.x =
                                    otherProduct.position.x +
                                    snappedProduct.width -
                                    latestWidth;
                            }
                        }

                        if (index === 3) {
                            const relativeX =
                                targetPos.x - otherProduct.position.x;

                            const isRightSide = relativeX > 0;

                            if (isRightSide) {
                                proposedPos.x =
                                    otherProduct.position.x +
                                    snappedProduct.width -
                                    latestWidth;
                            }
                        }

                        snapPosition.copy(proposedPos);

                        snapped = true;
                        isSnappedBackward = index === 3;
                    }
                });
            });

            if (snapped) {
                snapPosition.y = positionY;
                cabinetGroup.position.lerp(snapPosition, 0.3);

                overlapValidator.cabinet(
                    cabinetGroup,
                    productsRef.current,
                    (value) => {
                        value
                            ? triggerEvents(cabinetGroup, 'onOverlap', true)
                            : triggerEvents(cabinetGroup, 'onSnap', true);
                    }
                );

                overlapValidator.walls(scene, cabinetGroup, (value) => {
                    value
                        ? triggerEvents(cabinetGroup, 'onOverlap', true)
                        : triggerEvents(cabinetGroup, 'onSnap', true);
                });
            } else {
                if (!variables?.isUpperProduct) {
                    targetPos.y = positionY;
                    cabinetGroup.position.lerp(targetPos, 0.3);

                    triggerEvents(cabinetGroup, 'onSnap', false);
                } else {
                    const wallBoundingBox = new THREE.Box3().setFromObject(
                        wall
                    );
                    const wallMinY = wallBoundingBox.min.y;
                    const wallMaxY = wallBoundingBox.max.y;
                    const halfRoomDepth = roomDepth / 2;

                    const initialWallZ = cabinetGroup.position.z;
                    let adjustedZ = initialWallZ;

                    const nearLeftEdge =
                        Math.abs(dragPosition.x - wallBoundingBox.min.x) < 100;
                    const nearRightEdge =
                        Math.abs(
                            dragPosition.x -
                                (wallBoundingBox.max.x - width - 310 * scale)
                        ) < 100;

                    // Check if the wall is at the room’s edge
                    const isWallAtRoomEdge =
                        Math.abs(wall.position.z - -halfRoomDepth) < 50 ||
                        Math.abs(wall.position.z - roomDepth) < 50;

                    // Allow Z movement if near wall edges OR if the wall is at the room edge
                    if (nearLeftEdge || nearRightEdge || isWallAtRoomEdge) {
                        adjustedZ = THREE.MathUtils.clamp(
                            dragPosition.z,
                            initialWallZ - 150,
                            initialWallZ + 50
                        );
                    }

                    cabinetGroup.position.x = THREE.MathUtils.clamp(
                        dragPosition.x,
                        wallBoundingBox.min.x,
                        wallBoundingBox.max.x - width - 310 * scale
                    );

                    cabinetGroup.position.y = THREE.MathUtils.clamp(
                        dragPosition.y,
                        wallMinY + 50,
                        wallMaxY - 190
                    );

                    cabinetGroup.position.z = THREE.MathUtils.clamp(
                        adjustedZ,
                        -halfRoomDepth + height * scale,
                        roomDepth
                    );

                    overlapValidator.cabinet(
                        cabinetGroup,
                        productsRef.current,
                        (value) => {
                            value
                                ? triggerEvents(cabinetGroup, 'onOverlap', true)
                                : triggerEvents(cabinetGroup, 'onSnap', true);
                        }
                    );

                    overlapValidator.walls(scene, cabinetGroup, (value) => {
                        value
                            ? triggerEvents(cabinetGroup, 'onOverlap', true)
                            : triggerEvents(cabinetGroup, 'onSnap', true);
                    });
                }

                const direction = getDirection(cabinetGroup.rotation.z);
                const halfRoomWidth = roomWidth / 2;
                const halfRoomDepth = roomDepth / 2;

                let minX = -halfRoomWidth + 30;
                let maxX = halfRoomWidth - latestWidth + 40;
                let minZ = -halfRoomDepth + latestDepth - 15;
                let maxZ = halfRoomDepth - 16.5;

                if (direction === 'right') {
                    minX = minX + latestWidth;
                    maxX = maxX + latestWidth;
                }

                if (direction === 'left') {
                    minX = minX;
                    maxX = maxX;

                    minZ = minZ - latestDepth + 5;
                    maxZ = maxZ - latestDepth + 5;
                }

                if (direction === 'back') {
                    minZ = minZ - latestDepth + 5;
                    maxZ = maxZ - latestDepth + 5;

                    minX = minX + latestWidth;
                    maxX = maxX + latestWidth;
                }

                if (roomType === 'LSHAPED') {
                    if (
                        cabinetGroup.position.x >
                        roomLeftDepth * scale -
                            (roomRightWidth * scale) / 2 -
                            latestWidth +
                            40
                    ) {
                        maxZ =
                            maxZ -
                            (Number(roomLeftWidth) * scale -
                                Number(roomRightDepth) * scale);
                    }
                }

                if (!(snapped && isSnappedBackward)) {
                    cabinetGroup.position.x = THREE.MathUtils.clamp(
                        cabinetGroup.position.x,
                        minX,
                        maxX
                    );
                    cabinetGroup.position.z = THREE.MathUtils.clamp(
                        cabinetGroup.position.z,
                        minZ,
                        maxZ
                    );
                }
            }
        };

        const onMouseUp = () => {
            isDragging = false;
            dispatch(setIsDragging(false));
            controls.current.enabled = true;
            triggerEvents(cabinetGroup, 'onClick', true);

            if (cabinetGroup.userData.selected) {
                setSelectedCabinetGroup(cabinetGroup);
                cabinetGroup.userData.selected = false;
                debouncedSave(cabinetGroup, roomType);
            }

            overlapValidator.cabinet(
                cabinetGroup,
                productsRef.current,
                (value) => {
                    value && triggerEvents(cabinetGroup, 'onOverlap', true);
                }
            );

            overlapValidator.walls(scene, cabinetGroup, (value) => {
                value && triggerEvents(cabinetGroup, 'onOverlap', true);
            });
        };

        const handleRightClick = (event: MouseEvent) => {
            event.preventDefault();
            const dimension = mountRef.current.getBoundingClientRect();
            mouse.x =
                ((event.clientX - dimension.left) / dimension.width) * 2 - 1;
            mouse.y =
                (-(event.clientY - dimension.top) / dimension.height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera.current);

            const intersects = raycaster.intersectObject(cabinetGroup, true);

            if (intersects.length > 0) {
                triggerEvents(cabinetGroup, 'toggleDoor', {
                    doorGap: product.cabinet_door_gap,
                });
            }
        };

        const handleSelect = debounce(() => {
            const sortedByDistance = intersectionsRef.current?.sort(
                (a, b) => a.distance - b.distance
            );
            const closestIntersect = sortedByDistance?.[0];

            if (
                closestIntersect &&
                closestIntersect?.id == cabinetGroup.userData?.id
            ) {
                dispatch(setActiveGroup(cabinetGroup));
                selectCabinet(cabinetGroup, camera.current, renderer.current);
                cabinetGroup.userData.selected = true;
                dispatch(setSelectedProductValue(cabinetGroup.userData.values));
                intersectionsRef.current = [];

                const values = cabinetGroup.userData.values;
                if (values && isRoomPlannerEditing) {
                    searchParams.set(
                        'cabinetId',
                        values.job_cabinet_id?.toString() || ''
                    );
                    searchParams.set('product', values.type?.toString() || '');
                    setSearchParams(searchParams);
                }
            }
        }, 20);

        const handleLeftClick = (event: MouseEvent) => {
            event.preventDefault();

            const dimension = mountRef.current.getBoundingClientRect();

            // Calculate normalized device coordinates (NDC) for the mouse
            mouse.x =
                ((event.clientX - dimension.left) / dimension.width) * 2 - 1;
            mouse.y =
                (-(event.clientY - dimension.top) / dimension.height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera.current);

            // Perform raycasting to detect intersected objects
            const intersects = raycaster.intersectObject(cabinetGroup, true);

            const sceneIntersect = raycaster.intersectObjects(
                scene.children,
                true
            );

            intersects.forEach((intersect) => {
                const intersectedGroup = findParentWithId(
                    intersect.object
                ) as THREE.Group<THREE.Object3DEventMap>;

                const pattern = /^\d+-\d$/;
                if (
                    typeof intersectedGroup.userData.id === 'number' ||
                    pattern.test(intersectedGroup.userData.id)
                ) {
                    intersectionsRef.current.push({
                        distance: intersect.distance,
                        id: intersectedGroup.userData.id,
                        group: intersectedGroup,
                    });
                }
            });

            const sortedByDistance = sceneIntersect.sort(
                (a, b) => a.distance - b.distance
            );

            // validate objects that were selected
            if (sceneIntersect.length <= 0) {
                dispatch(setIsRoomPlannerEditing(false));
                dispatch(setActiveGroup(null));
                setSearchParams('');
                hideMenu && hideMenu();
            }

            // if object is not cabinet, deselect
            if (
                (sortedByDistance &&
                    sortedByDistance[0]?.object.name.indexOf('floor') !== -1) ||
                sortedByDistance[0]?.object.name.indexOf('wall') !== -1
            ) {
                dispatch(setIsRoomPlannerEditing(false));
                dispatch(setActiveGroup(null));
                setSearchParams('');
                hideMenu && hideMenu();
            }

            handleSelect();
        };

        const domElement = renderer.current.domElement;

        const eventListeners = {
            mousedown: onMouseDown,
            mousemove: onMouseMove,
            mouseup: onMouseUp,
            contextmenu: handleRightClick,
            click: handleLeftClick,
        };

        Object.entries(eventListeners).forEach(([event, handler]) => {
            domElement.addEventListener(event, handler);
        });

        eventHandlersRef.current.push(eventListeners);
    };

    const clearEvents = () => {
        if (eventHandlersRef.current) {
            const domElement = renderer.current.domElement;

            eventHandlersRef.current.forEach((events) => {
                Object.entries(events).forEach(([event, handler]) => {
                    //@ts-ignore
                    domElement.removeEventListener(event, handler);
                });
            });
        }
    };

    useEffect(() => {
        if (showArrows) {
            setShowArrows(true);
        } else {
            setShowArrows(false);
        }
    }, [showArrows]);

    return {
        cabinetGroup: selectedCabinetGroup,
        attachEvents,
        clearEvents,
        getGroupWidth,
        triggerEvents,
    };
};

export default useProductEvents;
