import * as THREE from 'three';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';

/* eslint-disable */
export const addDashedLine = (
    parentMesh: THREE.Mesh,
    handleLines: (lines: THREE.Line[]) => void,
    floorWidth: number,
    floorLength: number
) => {
    const dashedMaterial = new THREE.LineDashedMaterial({
        color: '#333',
        gapSize: 3,
    });

    // Geometry for the lines
    const leftLineGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(floorWidth / 2 - 50, 300, 0),
        new THREE.Vector3(-floorWidth / 2 - 50, 300, 0),
    ]);
    const rightLineGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(floorLength / 2 + 50, 300, 0),
        new THREE.Vector3(-floorLength / 2, 300, 0),
    ]);

    const leftLine = new THREE.Line(leftLineGeometry, dashedMaterial);
    const rightLine = new THREE.Line(rightLineGeometry, dashedMaterial);

    // Compute line distances for dashes
    leftLine.computeLineDistances();
    rightLine.computeLineDistances();

    leftLine.position.z = 27;
    rightLine.position.z = 27;
    rightLine.rotation.z = (90 * Math.PI) / 180;

    parentMesh.add(leftLine);
    parentMesh.add(rightLine);

    const leftLine2 = leftLine.clone();
    const rightLine2 = rightLine.clone();

    leftLine2.position.y = 200;
    rightLine2.position.x = 200;

    rightLine.position.y = floorLength / 2 - 25;
    rightLine2.position.y = floorLength / 2 - 25;
    leftLine.position.x = floorWidth / 2 + 25;
    leftLine2.position.x = floorWidth / 2 + 25;

    parentMesh.add(leftLine2);
    parentMesh.add(rightLine2);

    leftLine.userData.id = 'leftLine';
    leftLine2.userData.id = 'leftLine2';
    rightLine.userData.id = 'rightLine';
    rightLine2.userData.id = 'rightLine2';

    handleLines([leftLine, leftLine2, rightLine, rightLine2]);

    leftLine.visible = false;
    leftLine2.visible = false;
    rightLine.visible = false;
    rightLine2.visible = false;

    return {
        leftLine,
        leftLine2,
        rightLine,
        rightLine2,
    };
};

export const addArrowsAndText = (
    wall: THREE.Mesh,
    width: number,
    handleArrows: (
        arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[]
    ) => void,
    isSideWall: boolean = false,
    measurement = 0,
    wallHeight = 0,
    id = ''
) => {
    // Arrow Helper
    const arrowDir = new THREE.Vector3(1, 0, 0).normalize();
    const arrowLength = width - 50 - (isSideWall ? 25 : 0);
    const arrowColor = '#333';

    // Create left arrow
    const leftArrow = new THREE.ArrowHelper(
        arrowDir.clone().negate(), // Direction
        new THREE.Vector3(width - 75 + (isSideWall ? 25 : 0), wallHeight, 12.5), // Start point
        arrowLength,
        arrowColor,
        15,
        15
    );
    wall.add(leftArrow);

    // Create right arrow
    const rightArrow = new THREE.ArrowHelper(
        arrowDir,
        new THREE.Vector3(width + 75, wallHeight, 12.5),
        arrowLength,
        arrowColor,
        15,
        15
    );
    wall.add(rightArrow);

    // Text for width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 256;
    canvas.height = 128;

    context!.font = '50px Arial';
    context!.fillStyle = '#000';
    context!.textAlign = 'center';
    context!.fillText(`${measurement} mm`, canvas.width / 2, canvas.height / 2);

    const texture = new THREE.CanvasTexture(canvas);
    const textMaterial = new THREE.SpriteMaterial({map: texture});
    const textSprite = new THREE.Sprite(textMaterial);
    textSprite.position.set(width + (isSideWall ? 25 : 0), wallHeight, 20);
    textSprite.scale.set(100, 50, 1);

    wall.add(textSprite);

    rightArrow.userData.id = id;
    leftArrow.userData.id = id;
    textMaterial.userData.id = id;

    handleArrows([rightArrow, leftArrow, textMaterial]);

    leftArrow.visible = false;
    rightArrow.visible = false;
    textMaterial.visible = false;
};

export const addArrowsAndTextForCabinetDimension = (
    wall: THREE.Mesh,
    width: number,
    handleArrowsAndGroup: (
        arrows: (THREE.Group | THREE.Mesh)[],
        arrowGroup: THREE.Group<THREE.Object3DEventMap>
    ) => void,
    isSideWall: boolean = false,
    isLshapedRoom: boolean = false
) => {
    const arrowDir = new THREE.Vector3(1, 0, 0).normalize();
    let arrowLength = width - 10;
    const arrowColor = 0x333333; // Convert hex string to number

    const createArrow = (start: THREE.Vector3, direction: THREE.Vector3) => {
        const arrowGroup = new THREE.Group();

        const createTube = (length: number) => {
            const path = new THREE.LineCurve3(
                start,
                start.clone().add(direction.clone().multiplyScalar(length))
            );
            const tubeGeometry = new THREE.TubeGeometry(path, 15, 1, 8, false);
            const tubeMaterial = new THREE.MeshBasicMaterial({
                color: arrowColor,
            });
            return new THREE.Mesh(tubeGeometry, tubeMaterial);
        };

        const createCone = (length: number) => {
            const coneGeometry = new THREE.ConeGeometry(4, 10, 8);
            const coneMaterial = new THREE.MeshBasicMaterial({
                color: arrowColor,
            });
            const coneMesh = new THREE.Mesh(coneGeometry, coneMaterial);
            coneMesh.position.copy(
                start.clone().add(direction.clone().multiplyScalar(length))
            );
            coneMesh.lookAt(start);
            return coneMesh;
        };

        let tubeMesh = createTube(arrowLength);
        let coneMesh = createCone(arrowLength);

        arrowGroup.add(tubeMesh, coneMesh);

        // Store references for updating
        arrowGroup.userData.tubeMesh = tubeMesh;
        arrowGroup.userData.coneMesh = coneMesh;
        arrowGroup.userData.updateArrow = (
            newLength: number,
            totalWidth: number
        ) => {
            arrowLength = newLength;
            arrowGroup.remove(tubeMesh, coneMesh);
            tubeMesh.geometry.dispose();
            coneMesh.geometry.dispose();
            tubeMesh = createTube(arrowLength);
            coneMesh = createCone(arrowLength);
            arrowGroup.add(tubeMesh, coneMesh);
            arrowGroup.userData.tubeMesh = tubeMesh;
            arrowGroup.userData.coneMesh = coneMesh;

            if (isSideWall) arrowGroup.position.x = totalWidth / 2 - 65;
            else arrowGroup.position.x = -(totalWidth / 2 - 133);
        };

        return arrowGroup;
    };

    const leftArrow = createArrow(
        new THREE.Vector3(width - 15, 25, 40),
        arrowDir.clone().negate()
    );

    const rightArrow = createArrow(
        new THREE.Vector3(width + 25, 25, 40),
        arrowDir
    );

    const arrowGroup = new THREE.Group();
    arrowGroup.userData.id = isSideWall ? 'arrowGroupLeft' : 'arrowGroupTop';

    arrowGroup.add(rightArrow);
    arrowGroup.add(leftArrow);

    wall.add(arrowGroup);

    // Load font and create text
    const loader = new FontLoader();
    loader.load('/templates/3D/fonts/Poppins SemiBold_Regular.json', (font) => {
        const createTextMesh = (text: string, position: THREE.Vector3) => {
            const textGeometry = new TextGeometry(text, {
                font: font,
                size: 4.5,
                depth: 1,
            });
            const textMaterial = new THREE.MeshBasicMaterial({color: 0x000000});
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);
            textMesh.position.copy(position);
            return textMesh;
        };

        let textMesh = createTextMesh(`0 mm`, new THREE.Vector3(width, 30, 41));

        arrowGroup.add(textMesh);
        arrowGroup.userData.textMesh = textMesh;

        arrowGroup.userData.onDrag = (width: number, depth: number) => {
            arrowGroup.visible = true;
            const computedWidth = isSideWall ? depth : width;

            const newWidth = computedWidth / 2;
            const newArrowLength = newWidth - 25;

            // Update arrows
            leftArrow.userData.updateArrow(newArrowLength, computedWidth);
            rightArrow.userData.updateArrow(newArrowLength, computedWidth);

            // Update text position & content
            arrowGroup.remove(textMesh);
            textMesh.geometry.dispose();

            let textMeshPositionX = -newWidth + 211;
            if (isSideWall) textMeshPositionX = newWidth - 23;
            if (!isSideWall && isLshapedRoom) textMeshPositionX = newWidth - 15;

            textMesh = createTextMesh(
                `${(computedWidth / 0.2).toFixed(2)} mm`,
                new THREE.Vector3(textMeshPositionX, 30, isSideWall ? 41 : 38)
            );

            if (isSideWall) textMesh.rotateX(-Math.PI / 2);
            else {
                textMesh.rotateX(-Math.PI / 2);
                if (!isLshapedRoom) {
                    textMesh.rotateZ(Math.PI);
                }
            }

            arrowGroup.add(textMesh);
            arrowGroup.userData.textMesh = textMesh;
        };

        arrowGroup.userData.onDragEnd = () => {
            arrowGroup.visible = false;
        };
    });

    handleArrowsAndGroup([rightArrow, leftArrow], arrowGroup);

    leftArrow.visible = false;
    rightArrow.visible = false;
};

export const createRoomDimensionArrow = ({
    arrowDir,
    reverseArrowDir,
    arrowOrigin,
    arrowLength,
    onArrowsCreated,
    isVertical = false,
}: {
    arrowDir: THREE.Vector3;
    reverseArrowDir: THREE.Vector3;
    arrowOrigin: THREE.Vector3;
    arrowLength: number;
    isVertical?: boolean;
    onArrowsCreated?: (
        arrow: THREE.ArrowHelper,
        reverseArrow: THREE.ArrowHelper
    ) => void;
}): THREE.Group<THREE.Object3DEventMap> => {
    const arrowHeadLength = 20;
    const arrowHeadWidth = 16;
    let reverserArrowOrigin = new THREE.Vector3(
        arrowOrigin.x + arrowLength,
        arrowOrigin.y,
        arrowOrigin.z
    );

    if (isVertical) {
        reverserArrowOrigin = new THREE.Vector3(
            arrowOrigin.x,
            arrowOrigin.y + arrowLength,
            arrowOrigin.z
        );
    }

    const arrow = new THREE.ArrowHelper(
        arrowDir,
        arrowOrigin,
        arrowLength,
        0x000000,
        arrowHeadLength,
        arrowHeadWidth
    );

    // Create reverse arrow for double-header effect (top)
    const reverseArrow = new THREE.ArrowHelper(
        reverseArrowDir,
        reverserArrowOrigin,
        arrowLength,
        0x000000,
        arrowHeadLength,
        arrowHeadWidth
    );

    onArrowsCreated && onArrowsCreated(arrow, reverseArrow);

    const arrowGroup = new THREE.Group();
    arrowGroup.add(arrow, reverseArrow);

    return arrowGroup;
};
