import * as THREE from 'three';
import ObjectNameMap from '../constants/ObjectNameMap';

const useCabinetOverlap = () => {
    const checkIfOverlapsOtherCabinets = (
        currentCabinet: THREE.Group<THREE.Object3DEventMap>,
        cabinetList: THREE.Group<THREE.Object3DEventMap>[],
        onOverlap: (value: boolean) => void
    ) => {
        cabinetList.forEach((otherProduct) => {
            if (otherProduct.userData.id == currentCabinet.userData.id) return;
            const box = new THREE.Box3().setFromObject(otherProduct);
            const otherBox = new THREE.Box3().setFromObject(currentCabinet);

            if (box.intersectsBox(otherBox)) {
                const overlapThreshold = 3; // increase for more tolerance
                const boxMin = box.min.clone();
                const boxMax = box.max.clone();
                const otherBoxMin = otherBox.min.clone();
                const otherBoxMax = otherBox.max.clone();

                const xOverlap =
                    boxMax.x > otherBoxMin.x + overlapThreshold &&
                    boxMin.x < otherBoxMax.x - overlapThreshold;
                const zOverlap =
                    boxMax.z > otherBoxMin.z + overlapThreshold &&
                    boxMin.z < otherBoxMax.z - overlapThreshold;

                if (xOverlap && zOverlap) {
                    onOverlap(true);
                } else {
                    onOverlap(false);
                }
            }
        });
    };

    const checkIfOverlapsWall = (
        scene: THREE.Scene,
        currentCabinet: THREE.Group<THREE.Object3DEventMap>,
        onOverlap: (value: boolean) => void
    ) => {
        const wall = scene.children.find(
            (ch) =>
                ch.name === ObjectNameMap.rectangularWall ||
                ch.name === ObjectNameMap.lShapeWall
        ) as THREE.Mesh<THREE.ExtrudeGeometry, THREE.MeshStandardMaterial>;

        wall.children.forEach((wall) => {
            const box = new THREE.Box3().setFromObject(wall);
            const otherBox = new THREE.Box3().setFromObject(currentCabinet);

            if (box.intersectsBox(otherBox)) {
                const overlapThreshold = 18; // increase for more tolerance
                const boxMin = box.min.clone();
                const boxMax = box.max.clone();
                const otherBoxMin = otherBox.min.clone();
                const otherBoxMax = otherBox.max.clone();

                const xOverlap =
                    boxMax.x > otherBoxMin.x + overlapThreshold &&
                    boxMin.x < otherBoxMax.x - overlapThreshold;
                const zOverlap =
                    boxMax.z > otherBoxMin.z + overlapThreshold &&
                    boxMin.z < otherBoxMax.z - overlapThreshold;

                if (xOverlap && zOverlap) {
                    onOverlap(true);
                } else {
                    onOverlap(false);
                }
            }
        });
    };

    const overlapValidator = {
        walls: checkIfOverlapsWall,
        cabinet: checkIfOverlapsOtherCabinets,
    };

    return {
        overlapValidator,
    };
};

export default useCabinetOverlap;
