import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from 'store/customer';
import useRoomPlanner from 'components/customer/RoomPlanner/lib/useRoomPlanner';
import Preview3DErrorBoundary from 'components/customer/Preview3D/components/Preview3DErrorBoundary';
import RoomLayoutModal from 'components/customer/RoomPlanner/components/RoomLayoutModal';
import {
    setRoomLayoutModalOpen,
    getRoomLayoutModalOpen,
    getIsAutoSaving,
    getIsTopView,
    getRoomType,
    getIsEditHeightView,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {useSidebarStateToggle} from 'shared/helpers/useSidebarStateToggle';
import RoomDimensionOverlay from 'components/customer/RoomPlanner/components/RoomDimensionOverlay';
import FloatingComponent from './FloatingMenu/FloatingComponent';
import FloatingMenu from './FloatingMenu/FloatingMenu';
import RoomHeightOverlay from 'components/customer/RoomPlanner/components/RoomHeightOverlay';
import {Spinner} from 'react-bootstrap';

const RoomPlannerDashboard = () => {
    useSidebarStateToggle();

    const {mountRef} = useRoomPlanner();
    const dispatch = useAppDispatch();
    const isAutoSaving = useAppSelector(getIsAutoSaving);
    const isRoomLayoutModalOpen = useAppSelector(getRoomLayoutModalOpen);
    const isTopView = useAppSelector(getIsTopView);
    const isEditHeightView = useAppSelector(getIsEditHeightView);
    const roomType = useAppSelector(getRoomType);

    const handleModalOpen = useCallback((show: boolean) => {
        dispatch(setRoomLayoutModalOpen(show));
    }, []);

    return (
        <Preview3DErrorBoundary>
            <RoomPlannerContainer>
                {isTopView && roomType ? <RoomDimensionOverlay /> : null}
                {isEditHeightView && roomType ? <RoomHeightOverlay /> : null}
                <FloatingComponent containerRef={mountRef}>
                    <FloatingMenu />
                </FloatingComponent>
                <RoomPlanner ref={mountRef} />
                <RoomLayoutModal
                    open={isRoomLayoutModalOpen}
                    setModalOpen={handleModalOpen}
                />
                <SavingContainer
                    className={`d-flex align-items-center gap-2 transition-opacity ${
                        isAutoSaving ? 'opacity-100' : 'opacity-0'
                    }`}>
                    <div className="d-flex align-items-center gap-2">
                        <Spinner animation="border" size="sm" />
                        <span>Saving</span>
                    </div>
                </SavingContainer>
            </RoomPlannerContainer>
        </Preview3DErrorBoundary>
    );
};

const RoomPlannerContainer = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const RoomPlanner = styled.div`
    height: 100%;
    flex-grow: 1;
`;

const SavingContainer = styled.div`
    position: fixed;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
`;

export default RoomPlannerDashboard;
