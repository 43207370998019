import {useCallback} from 'react';
import {matchPath, useLocation, useSearchParams} from 'react-router-dom';

export const useProductSaveRedirection = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleRedirection = useCallback(() => {
        const match = matchPath(
            '/v2/job/:jobId/room/:roomId/layout',
            location.pathname
        );

        if (match) {
            searchParams.delete('product');
            setSearchParams(searchParams);

            return true;
        }
    }, [location, searchParams, setSearchParams]);

    return {
        handleRedirection,
    };
};
