import {useRef} from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
// import {useProductContext} from 'contexts';
import {PreviewFormValues} from 'Preview3D/types';

const useAdjustableLegs = (isLeftReturn = false) => {
    const adjustableLegModel =
        useRef<THREE.Group<THREE.Object3DEventMap | null>>(null);

    // // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    // const {adjustableLegsQuantity} = useProductContext() as {
    //     adjustableLegsQuantity: number;
    // };

    const adjustableLegsQuantity = 5;

    const transformAdjustableLegsModel = (
        type:
            | 'BOTTOM_LEFT'
            | 'TOP_RIGHT'
            | 'TOP_LEFT'
            | 'BOTTOM_RIGHT'
            | 'FRONT'
            | 'BACK',
        model: THREE.Group<THREE.Object3DEventMap>,
        values: PreviewFormValues
    ): THREE.Group<THREE.Object3DEventMap> => {
        const degreesX = 90;
        const rotationX = (degreesX * Math.PI) / 180;
        const degreesY = 180;
        const rotationY = (degreesY * Math.PI) / 180;
        const legsZOffset = -109;
        const modelScale = 750;
        const includeFaces =
            typeof values.include_drawer_faces !== 'undefined'
                ? Boolean(values.include_drawer_faces)
                : true;
        const voidWidth =
            values.cabinet_void_width &&
            Boolean(values.cabinet_cover_void) &&
            includeFaces
                ? parseFloat(values.cabinet_void_width?.toString())
                : 0;

        const multiplier = isLeftReturn ? -1 : 1;
        const widthForRightLegs = values.cabinet_width - voidWidth * multiplier;
        const widthForLeftLegs = values.cabinet_width + voidWidth * multiplier;
        const xPosition = (-(voidWidth / 2) - 33 * multiplier) * multiplier;

        model.scale.set(modelScale, modelScale, modelScale);
        model.position.z = legsZOffset;
        model.rotation.x = rotationX;

        switch (type) {
            case 'TOP_RIGHT':
                model.rotation.y = rotationY;
                model.position.x = widthForRightLegs / 2;
                model.position.y = -(values.cabinet_depth / 2) + 100;
                break;
            case 'TOP_LEFT':
                model.position.x = -(widthForLeftLegs / 2);
                model.position.y = -(values.cabinet_depth / 2) + 35;
                break;
            case 'BOTTOM_RIGHT':
                model.rotation.y = rotationY;
                model.position.x = widthForRightLegs / 2;
                model.position.y = values.cabinet_depth / 2 - 35;
                break;
            case 'BACK':
                model.position.x = voidWidth !== 0 ? xPosition : -33;
                model.position.y = values.cabinet_depth / 2 - 110;
                break;
            case 'FRONT':
                model.position.x = voidWidth !== 0 ? xPosition : -33;
                model.position.y = -(values.cabinet_depth / 2) + 35;
                break;
            default:
                model.position.x = -(widthForLeftLegs / 2);
                model.position.y = values.cabinet_depth / 2 - 100;
                break;
        }

        return model;
    };

    const addAdjustableLegs = (
        model: THREE.Group<THREE.Object3DEventMap>,
        cabinet: THREE.Group<THREE.Object3DEventMap>,
        values: PreviewFormValues
    ) => {
        const legMeshGroup = new THREE.Group();

        if (values.cabinet_depth >= 250) {
            const bottomLeftModel = transformAdjustableLegsModel(
                'BOTTOM_LEFT',
                model.clone(),
                values
            );
            legMeshGroup.add(bottomLeftModel);
        }

        const topRightModel = transformAdjustableLegsModel(
            'TOP_RIGHT',
            model.clone(),
            values
        );
        legMeshGroup.add(topRightModel);

        const topLeftModel = transformAdjustableLegsModel(
            'TOP_LEFT',
            model.clone(),
            values
        );
        legMeshGroup.add(topLeftModel);

        if (values.cabinet_depth >= 250) {
            const bottomRightModel = transformAdjustableLegsModel(
                'BOTTOM_RIGHT',
                model.clone(),
                values
            );
            legMeshGroup.add(bottomRightModel);
        }

        if (adjustableLegsQuantity >= 5) {
            const frontModel = transformAdjustableLegsModel(
                'FRONT',
                model.clone(),
                values
            );
            legMeshGroup.add(frontModel);
        }

        if (adjustableLegsQuantity >= 6 && values.cabinet_depth >= 250) {
            const backModel = transformAdjustableLegsModel(
                'BACK',
                model.clone(),
                values
            );
            legMeshGroup.add(backModel);
        }
        legMeshGroup.position.y = values.cabinet_depth / 2;
        legMeshGroup.position.x = values.cabinet_width / 2;
        cabinet.add(legMeshGroup);
    };

    const applyDynamicAdjustableLegs = (
        cabinet: THREE.Group<THREE.Object3DEventMap>,
        values: PreviewFormValues
    ) => {
        if (adjustableLegModel.current) {
            addAdjustableLegs(adjustableLegModel.current, cabinet, values);
        } else {
            const loader = new GLTFLoader();

            loader.load(
                '/templates/3D/models/adjustable_leg.glb',
                function (gltf) {
                    const model = gltf.scene;
                    if (!Boolean(values.cabinet_include_hardware)) {
                        model.traverse(function (
                            child: THREE.Mesh & {
                                material: {
                                    transparent: boolean;
                                    opacity: number;
                                };
                            }
                        ) {
                            if (child.isMesh && child.material) {
                                child.material.transparent = true;
                                child.material.opacity = 0.1;
                            }
                        });
                    }

                    addAdjustableLegs(model, cabinet, values);
                }
            );
        }
    };

    return {
        applyDynamicAdjustableLegs,
    };
};

export default useAdjustableLegs;
