import {useRef, useCallback, MutableRefObject} from 'react';
import * as THREE from 'three';

const useRendererSetup = () => {
    const renderer = useRef<THREE.WebGLRenderer>();

    const setupRenderer = useCallback(
        (mountRef: MutableRefObject<HTMLDivElement>) => {
            const dimension = mountRef.current.getBoundingClientRect();

            renderer.current = new THREE.WebGLRenderer({antialias: true});
            renderer.current.shadowMap.enabled = true;
            renderer.current.shadowMap.type = THREE.PCFSoftShadowMap;
            renderer.current.autoClear = false;
            renderer.current.setSize(dimension.width, dimension.height);

            mountRef.current.appendChild(renderer.current.domElement);

            return renderer;
        },
        []
    );

    return {
        setupRenderer,
        renderer,
    };
};

export default useRendererSetup;
