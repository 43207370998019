import * as THREE from 'three';

export const getMeshSizeInPixels = (
    mesh: THREE.Object3D,
    camera: THREE.Camera,
    renderer: THREE.WebGLRenderer
): {width: number; height: number} => {
    // Compute the bounding box of the mesh
    const bbox = new THREE.Box3().setFromObject(mesh);

    // If the bounding box is empty, return 0 size
    if (!bbox) return {width: 0, height: 0};

    // Get min and max world positions
    const min = bbox.min.clone();
    const max = bbox.max.clone();

    // Convert to screen space
    const minScreen = worldToScreen(min, camera, renderer);
    const maxScreen = worldToScreen(max, camera, renderer);

    // Compute width and height in pixels
    const width = Math.abs(maxScreen.x - minScreen.x);
    const height = Math.abs(maxScreen.y - minScreen.y);

    return {width, height};
};

export const worldToScreen = (
    worldPos: THREE.Vector3,
    camera: THREE.Camera,
    renderer: THREE.WebGLRenderer
): {x: number; y: number} => {
    const width = renderer.domElement.width;
    const height = renderer.domElement.height;

    // Convert world position to Normalized Device Coordinates (NDC)
    const ndc = worldPos.project(camera);

    // Convert NDC to screen space
    return {
        x: (ndc.x + 1) * 0.5 * width,
        y: (1 - ndc.y) * 0.5 * height,
    };
};

export const addEdgeLines = (mesh: THREE.Mesh, name: string) => {
    const edgeMaterial = new THREE.LineBasicMaterial({
        color: '#000',
        transparent: true,
        opacity: 0,
    });

    const edges = new THREE.EdgesGeometry(mesh.geometry);
    const line = new THREE.LineSegments(edges, edgeMaterial);
    line.name = name;
    mesh.add(line);

    return {line, edgeMaterial};
};
