import React from 'react';
import FloatingMenuOption from './FloatingMenuOption';

const FloatingMenu = () => {
    return (
        <>
            <FloatingMenuOption.Edit />
            <FloatingMenuOption.Copy />
            <FloatingMenuOption.DoorToggle />
            <FloatingMenuOption.RotateLeft />
            <FloatingMenuOption.RotateRight />
            <FloatingMenuOption.Delete />
        </>
    );
};

export default FloatingMenu;
