import React, {useState, useCallback, useEffect} from 'react';
import {useFormik} from 'formik';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {
    getRectangularElementDimension,
    updateDimension,
    getRoomDimensions,
    getTopViewZoom,
    setPreviewPosition,
    getRoomType,
    getLShapedElementDimension,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {shallowEqual} from 'react-redux';
import {RoomType} from 'components/customer/RoomPlanner/types';
import styled from 'styled-components';
import {useDebouncedCallback} from 'use-debounce';
import {CBCButton} from 'shared/helpers';

const RoomDimensionOverlay = () => {
    const dispatch = useAppDispatch();
    const rectangularElementDimension = useAppSelector(
        getRectangularElementDimension
    );
    const lShapedElementDimension = useAppSelector(getLShapedElementDimension);
    const {rectangularDimension, lShapedDimension} = useAppSelector(
        getRoomDimensions,
        shallowEqual
    );
    const topViewZoom = useAppSelector(getTopViewZoom);
    const roomType = useAppSelector(getRoomType);
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [lShapedWidth, setLShapedWidth] = useState(0);
    const [lShapedLength, setLShapedLength] = useState(0);
    const [lShapedRightDepth, setLShapedRightDepth] = useState(0);
    const [lShapedLeftDepth, setLShapedLeftDepth] = useState(0);

    useEffect(() => {
        if (rectangularElementDimension) {
            setWidth(rectangularElementDimension?.width * topViewZoom + 25);
            setLength(
                rectangularElementDimension?.length * topViewZoom -
                    (rectangularElementDimension?.length * topViewZoom) / 7.8
            );
        }
    }, [rectangularElementDimension, topViewZoom]);

    useEffect(() => {
        if (lShapedElementDimension) {
            setLShapedWidth(
                lShapedElementDimension?.rightWidth * topViewZoom + 25
            );
            setLShapedLength(
                lShapedElementDimension?.leftWidth * topViewZoom -
                    (lShapedElementDimension?.leftWidth * topViewZoom) / 7.8
            );
            setLShapedRightDepth(
                lShapedElementDimension?.rightDepth * topViewZoom
            );
            setLShapedLeftDepth(
                lShapedElementDimension?.leftDepth * topViewZoom
            );
        }
    }, [lShapedElementDimension, topViewZoom]);

    const formik = useFormik({
        initialValues: {
            ...rectangularDimension,
            ...lShapedDimension,
        },
        enableReinitialize: true,
        onSubmit: undefined,
    });

    const updateDimensionDebounced = useDebouncedCallback(
        (fieldName: string, roomType: RoomType, value: number) => {
            dispatch(
                updateDimension({
                    field: fieldName,
                    value: value,
                    type: roomType,
                })
            );
        },
        1000
    );

    const handleFieldChange = useCallback(
        (fieldName: string, roomType: RoomType) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                formik.handleChange(e);
                updateDimensionDebounced(
                    fieldName,
                    roomType,
                    Number(e.target.value)
                );
            },
        []
    );

    const handleConfirmShape = useCallback(() => {
        dispatch(setPreviewPosition('LEFT_LEVELED_STATIC'));
    }, [dispatch]);

    return (
        <>
            {roomType === 'RECTANGULAR' ? (
                <Rectangle width={width} length={length}>
                    {/* Top input */}
                    <InputContainer
                        style={{
                            top: '-55px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}>
                        <Label>Width</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="width"
                                value={formik.values.width}
                                onChange={handleFieldChange(
                                    'width',
                                    'RECTANGULAR'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>

                    {/* Left input */}
                    <InputContainer
                        style={{
                            left: '-100px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}>
                        <Label>Length</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="length"
                                value={formik.values.length}
                                onChange={handleFieldChange(
                                    'length',
                                    'RECTANGULAR'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>
                </Rectangle>
            ) : null}

            {roomType === 'LSHAPED' ? (
                <Rectangle width={lShapedWidth} length={lShapedLength}>
                    {/* Top input */}
                    <InputContainer
                        style={{
                            top: '-55px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}>
                        <Label>Right Width</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="rightWidth"
                                value={formik.values.rightWidth}
                                onChange={handleFieldChange(
                                    'rightWidth',
                                    'LSHAPED'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>

                    {/* Right input */}
                    <InputContainer
                        style={{
                            right: '-120px',
                            top: `${lShapedRightDepth / 2 - 40}px`,
                            transform: `translateY(-50%)`,
                        }}>
                        <Label>Right Depth</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="rightDepth"
                                value={formik.values.rightDepth}
                                onChange={handleFieldChange(
                                    'rightDepth',
                                    'LSHAPED'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>

                    {/* Bottom input */}
                    <InputContainer
                        style={{
                            bottom: '-65px',
                            left: `${lShapedLeftDepth / 2 + 30}px`,
                            transform: 'translateX(-50%)',
                        }}>
                        <Label>Left Depth</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="leftDepth"
                                value={formik.values.leftDepth}
                                onChange={handleFieldChange(
                                    'leftDepth',
                                    'LSHAPED'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>

                    {/* Left input */}
                    <InputContainer
                        style={{
                            left: '-100px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}>
                        <Label>Left Width</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="leftWidth"
                                value={formik.values.leftWidth}
                                onChange={handleFieldChange(
                                    'leftWidth',
                                    'LSHAPED'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>
                </Rectangle>
            ) : null}

            <ButtonContainer>
                <CBCButton
                    type="button"
                    onClick={handleConfirmShape}
                    iconName="Button-Tick.svg"
                    className="job-button button-blue">
                    Confirm Shape
                </CBCButton>
            </ButtonContainer>
        </>
    );
};

const Rectangle = styled.div<{width: number; length: number}>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width}px;
    height: ${(props) => props.length}px;
    margin-top: -35px;
`;

const InputContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

const Label = styled.span`
    font-size: 12px;
    color: #666;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const ArrowInput = styled.input`
    width: 100px;
    text-align: center;
    padding: 4px 25px 4px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const InputSuffix = styled.span`
    position: absolute;
    right: 8px;
    color: #666;
    font-size: 12px;
    pointer-events: none;
`;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: 52px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
`;

export default RoomDimensionOverlay;
