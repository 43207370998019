import React, {useCallback, useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {
    getRectangularElementDimension,
    updateDimension,
    getRoomDimensions,
    getTopViewZoom,
    setPreviewPosition,
    getRoomType,
    getLShapedElementDimension,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {shallowEqual} from 'react-redux';
import {RoomType} from 'components/customer/RoomPlanner/types';
import styled from 'styled-components';
import {useDebouncedCallback} from 'use-debounce';
import {CBCButton} from 'shared/helpers';

const RoomHeightOverlay = () => {
    const dispatch = useAppDispatch();
    const rectangularElementDimension = useAppSelector(
        getRectangularElementDimension
    );
    const {rectangularDimension, lShapedDimension} = useAppSelector(
        getRoomDimensions,
        shallowEqual
    );
    const lShapedElementDimension = useAppSelector(getLShapedElementDimension);
    const topViewZoom = useAppSelector(getTopViewZoom);
    const roomType = useAppSelector(getRoomType);
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);

    useEffect(() => {
        if (rectangularElementDimension) {
            setWidth(rectangularElementDimension?.length * topViewZoom + 25);
            setLength(rectangularElementDimension?.height * topViewZoom);
        }
    }, [rectangularElementDimension, topViewZoom]);

    useEffect(() => {
        if (lShapedElementDimension) {
            setWidth(lShapedElementDimension?.leftWidth * topViewZoom + 25);
            setLength(lShapedElementDimension?.rightWidth * topViewZoom);
        }
    }, [lShapedElementDimension, topViewZoom]);

    const formik = useFormik({
        initialValues: {
            ...rectangularDimension,
            ...lShapedDimension,
        },
        enableReinitialize: true,
        onSubmit: undefined,
    });

    const updateDimensionDebounced = useDebouncedCallback(
        (fieldName: string, roomType: RoomType, value: number) => {
            dispatch(
                updateDimension({
                    field: fieldName,
                    value: value,
                    type: roomType,
                })
            );
        },
        1000
    );

    const handleFieldChange = useCallback(
        (fieldName: string, roomType: RoomType) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                formik.handleChange(e);
                updateDimensionDebounced(
                    fieldName,
                    roomType,
                    Number(e.target.value)
                );
            },
        []
    );

    const handleConfirmHeight = useCallback(() => {
        dispatch(setPreviewPosition('RIGHT'));
    }, [dispatch]);

    return (
        <>
            {roomType === 'RECTANGULAR' ? (
                <Rectangle width={width} length={length}>
                    <InputContainer
                        style={{
                            left: '-100px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}>
                        <Label>Wall Height</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="height"
                                value={formik.values.height}
                                onChange={handleFieldChange(
                                    'height',
                                    'RECTANGULAR'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>
                </Rectangle>
            ) : null}

            {roomType === 'LSHAPED' ? (
                <Rectangle width={width} length={length}>
                    <InputContainer
                        style={{
                            left: '-100px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}>
                        <Label>Wall Height</Label>
                        <InputWrapper>
                            <ArrowInput
                                type="text"
                                name="height"
                                value={formik.values.height}
                                onChange={handleFieldChange(
                                    'height',
                                    'LSHAPED'
                                )}
                            />
                            <InputSuffix>mm</InputSuffix>
                        </InputWrapper>
                    </InputContainer>
                </Rectangle>
            ) : null}

            <ButtonContainer>
                <CBCButton
                    type="button"
                    onClick={handleConfirmHeight}
                    iconName="Button-Tick.svg"
                    className="job-button button-blue">
                    Confirm Wall Height
                </CBCButton>
            </ButtonContainer>
        </>
    );
};

const Rectangle = styled.div<{width: number; length: number}>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width}px;
    height: ${(props) => props.length}px;
    margin-top: 30px;
    margin-left: -50px;
`;

const InputContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

const Label = styled.span`
    font-size: 12px;
    color: #666;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const ArrowInput = styled.input`
    width: 100px;
    text-align: center;
    padding: 4px 25px 4px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const InputSuffix = styled.span`
    position: absolute;
    right: 8px;
    color: #666;
    font-size: 12px;
    pointer-events: none;
`;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: 52px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
`;

export default RoomHeightOverlay;
