import {useEffect, useRef} from 'react';
import * as THREE from 'three';
import {RoomType} from 'components/customer/RoomPlanner/types';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    getHiddenWall,
    getIsTopView,
    setHiddenWall,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';

const useWallHider = (
    wallRefs: React.MutableRefObject<
        THREE.Mesh<
            THREE.ExtrudeGeometry,
            THREE.MeshStandardMaterial,
            THREE.Object3DEventMap
        >[]
    >,
    roomType: RoomType
) => {
    const hiddenWall = useAppSelector(getHiddenWall);
    const dispatch = useAppDispatch();
    const lastZone = useRef('');
    const isTopView = useAppSelector(getIsTopView);

    const checkCameraZone = (camera: THREE.OrthographicCamera) => {
        const {x, z} = camera.position;
        let newZone = '';

        if (z > 0 && Math.abs(x) < Math.abs(z)) {
            newZone = 'frontWall';
        }
        if (z < 0 && Math.abs(x) < Math.abs(z)) {
            newZone = 'backWall';
        }
        if (x > 0 && Math.abs(z) < Math.abs(x * 0.2)) {
            newZone = 'rightWall';
        }
        if (x < 0 && Math.abs(z) < Math.abs(x * 1.8)) {
            newZone = 'leftWall';
        }

        if (newZone && newZone !== lastZone.current) {
            lastZone.current = newZone;
            dispatch(setHiddenWall(lastZone.current));
        }
    };

    useEffect(() => {
        if (hiddenWall) {
            if (roomType === 'RECTANGULAR') {
                wallRefs.current.forEach((wall) => {
                    (wall.userData as {onShow: () => void})?.onShow();
                    if (wall.userData.id == hiddenWall && !isTopView)
                        (wall.userData as {onHide: () => void})?.onHide();
                });

                wallRefs.current.forEach((wall) => {
                    let wallToHide = '';

                    switch (hiddenWall) {
                        case 'leftWall':
                            wallToHide = 'frontWall';
                            break;
                        case 'frontWall':
                            wallToHide = 'rightWall';
                            break;
                        case 'rightWall':
                            wallToHide = 'backWall';
                            break;
                        default:
                            wallToHide = 'leftWall';
                            break;
                    }

                    if (wall.userData.id == wallToHide && !isTopView)
                        (wall.userData as {onHide: () => void})?.onHide();
                });
            }

            if (roomType === 'LSHAPED') {
                wallRefs.current.forEach((wall) => {
                    (wall.userData as {onShow: () => void})?.onShow();

                    const wallsToHide: string[] = [];

                    switch (hiddenWall) {
                        case 'leftWall':
                            wallsToHide.push('leftDepthWall');
                            wallsToHide.push('leftWidthWall');
                            break;
                        case 'frontWall':
                            wallsToHide.push('leftDepthWall');
                            wallsToHide.push('leftWidth2Wall');
                            wallsToHide.push('rightWidth2Wall');
                            break;
                        case 'rightWall':
                            wallsToHide.push('leftWidth2Wall');
                            wallsToHide.push('rightWidth2Wall');
                            wallsToHide.push('rightDepthWall');
                            break;
                        default:
                            wallsToHide.push('rightWidthWall');
                            wallsToHide.push('rightDepthWall');
                            break;
                    }

                    if (wallsToHide.includes(wall.userData.id as string))
                        (wall.userData as {onHide: () => void})?.onHide();
                });
            }
        }
    }, [hiddenWall, roomType, isTopView]);

    return {checkCameraZone, hiddenWall};
};

export default useWallHider;
