import * as THREE from 'three';
import {useAppDispatch} from 'store/customer';
import {setActiveGroupPosition} from '../store/roomPlannerSlice';

interface CabinetUserData {
    onCabinetSelect?: (isSelected: boolean) => void;
}

const useCabinetSelect = () => {
    const dispatch = useAppDispatch();

    const selectCabinet = (
        cabinet: THREE.Group<THREE.Object3DEventMap>,
        camera: THREE.OrthographicCamera,
        renderer: THREE.WebGLRenderer
    ) => {
        if (!cabinet) return;

        const worldPos = new THREE.Vector3();
        cabinet.getWorldPosition(worldPos);

        // Convert world position to screen position
        const screenPos = worldPos.clone().project(camera);
        const halfWidth = renderer.domElement.clientWidth / 2;
        const halfHeight = renderer.domElement.clientHeight / 2;
        const box = new THREE.Box3().setFromObject(cabinet);
        const height = box.max.y - box.min.y;

        dispatch(
            setActiveGroupPosition({
                screenPos,
                halfHeight,
                halfWidth,
                cabinetHeight: height,
            })
        );

        // Safely call the function on userData
        cabinet.traverse((child) => {
            const userData = child.userData as CabinetUserData;
            if (typeof userData.onCabinetSelect === 'function') {
                userData.onCabinetSelect(true);
            }
        });
    };

    return {
        selectCabinet,
    };
};

export default useCabinetSelect;
