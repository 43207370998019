import {useRef, useCallback, MutableRefObject, useEffect} from 'react';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {PreviewPosition} from 'Preview3D/types';
import TWEEN from '@tweenjs/tween.js';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {
    getPreviewPosition,
    getZoom,
    setPreviewPosition,
    setTopViewZoom,
    setZoom,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';

const useCameraSetup = () => {
    const camera = useRef<THREE.OrthographicCamera>();
    const controls = useRef<OrbitControls>();
    const previewPosition = useAppSelector(getPreviewPosition);
    const zoom = useAppSelector(getZoom);
    const dispatch = useAppDispatch();

    const setupCamera = useCallback(
        (
            mountRef: MutableRefObject<HTMLDivElement>,
            scene: THREE.Scene,
            renderer: THREE.WebGLRenderer
        ) => {
            if (mountRef.current) {
                const dimension = mountRef.current.getBoundingClientRect();

                const aspect = dimension.width / dimension.height;
                const frustumSize = 1500;

                const left = (-frustumSize * aspect) / 2;
                const right = (frustumSize * aspect) / 2;
                // const top = (-frustumSize * aspect) / 2;
                // const bottom = (frustumSize * aspect) / 2;
                const top = frustumSize / 1.2;
                const bottom = -frustumSize / 2.8;

                camera.current = new THREE.OrthographicCamera(
                    left,
                    right,
                    top,
                    bottom,
                    1,
                    20000
                );
                camera.current.zoom = zoom;
                camera.current.lookAt(0, 0, 0);

                scene = new THREE.Scene();
                scene.background = new THREE.Color(0xffffff);

                controls.current = new OrbitControls(
                    camera.current,
                    renderer.domElement
                );
                controls.current.enableDamping = true;

                return camera;
            }
        },
        [dispatch]
    );

    const setupCameraPosition = (position: PreviewPosition) => {
        const cameraPosition = {x: 0, y: 0, z: 0} as {
            x: number;
            y: number;
            z: number;
        };

        const isTopWithoutEditing = position === 'TOP_WITHOUT_EDITING';
        const isTop = position === 'TOP';
        const isLeftLeveledStatic = position === 'LEFT_LEVELED_STATIC';

        switch (position) {
            case 'RIGHT':
                cameraPosition.x = 750;
                cameraPosition.y = 623;
                cameraPosition.z = 1595;
                break;
            case 'LEFT_LEVELED':
            case 'LEFT_LEVELED_STATIC':
                cameraPosition.x = -1883;
                cameraPosition.y = -215;
                cameraPosition.z = 70;
                break;
            case 'FRONT':
                cameraPosition.x = 22;
                cameraPosition.y = -217;
                cameraPosition.z = 2040;
                break;
            case 'RIGHT_LEVELED':
                cameraPosition.x = 1980;
                cameraPosition.y = -218;
                cameraPosition.z = 77;
                break;
            case 'TOP':
            case 'TOP_WITHOUT_EDITING':
                cameraPosition.x = 13;
                cameraPosition.y = 2000;
                cameraPosition.z = 423.75;
                break;
            default:
                cameraPosition.x = -1240;
                cameraPosition.y = 600;
                cameraPosition.z = 1230;
                break;
        }

        if (isTop || isTopWithoutEditing) {
            camera.current.position.set(
                cameraPosition.x,
                cameraPosition.y,
                cameraPosition.z
            );
        } else {
            new TWEEN.Tween(camera.current.position)
                .to(
                    {
                        x: cameraPosition.x,
                        y: cameraPosition.y,
                        z: cameraPosition.z,
                    },
                    500
                )
                .easing(TWEEN.Easing.Cubic.Out)
                .start();
        }

        setupZoomAndControls(
            1,
            isTop,
            isLeftLeveledStatic,
            isTopWithoutEditing
        );
        dispatch(setZoom(1));
    };

    const setupZoomAndControls = (
        zoomOverride?: number,
        topView?: boolean,
        editHeightView?: boolean,
        topWithoutEditing?: boolean
    ) => {
        const roomLength = 8000;
        const topViewZoom = (8000 / roomLength) * 0.72;

        new TWEEN.Tween({zoom: camera.current.zoom})
            .to(
                {
                    zoom:
                        topView || editHeightView || topWithoutEditing
                            ? topViewZoom
                            : zoomOverride || zoom,
                },
                500
            )
            .onUpdate(function ({zoom}) {
                camera.current.zoom = zoom;
                camera.current.updateProjectionMatrix();
            })
            .onComplete(() => {
                dispatch(setPreviewPosition(null));
            })
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        if (controls.current) {
            if (topView || topWithoutEditing) {
                controls.current.target.set(13, 0, 423.75);
                if (topView) {
                    controls.current.enabled = false;
                    controls.current.enableRotate = false;
                    controls.current.enableZoom = false;
                    controls.current.enablePan = false;
                }

                dispatch(setTopViewZoom(topViewZoom));
            } else {
                new TWEEN.Tween(controls.current.target)
                    .to({x: 15, y: -213, z: 75}, 500)
                    .easing(TWEEN.Easing.Cubic.Out)
                    .start();
                if (!editHeightView) {
                    controls.current.enabled = true;
                    controls.current.enableRotate = true;
                    controls.current.enableZoom = true;
                    controls.current.enablePan = true;
                } else {
                    controls.current.enabled = false;
                    controls.current.enableRotate = false;
                    controls.current.enableZoom = false;
                    controls.current.enablePan = false;
                    dispatch(setTopViewZoom(topViewZoom));
                }
            }
        }
    };

    useEffect(() => {
        if (camera.current && previewPosition)
            setupCameraPosition(previewPosition);
    }, [previewPosition]);

    useEffect(() => {
        if (camera.current) setupZoomAndControls();
    }, [zoom]);

    return {
        setupCamera,
        camera,
        controls,
        setupCameraPosition,
    };
};

export default useCameraSetup;
