import {Event, pubSub} from 'components/customer/RoomPlanner/helper/PubSub';
import {PreviewType} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import React from 'react';

export const setPreview = (preview: React.ReactNode) => {
    pubSub.publish(Event.ROOM_PLANNER_3D_PREVIEW, preview);
};

export const setEdgePreview = (preview: React.ReactNode) => {
    pubSub.publish(Event.ROOM_PLANNER_EDGE_PREVIEW, preview);
};

export const setDoorPreview = (preview: React.ReactNode) => {
    pubSub.publish(Event.ROOM_PLANNER_DOOR_PREVIEW, preview);
};

export const setDrawerPreview = (preview: React.ReactNode) => {
    pubSub.publish(Event.ROOM_PLANNER_DRWAWER_PREVIEW, preview);
};

export const setShelvesPreview = (preview: React.ReactNode) => {
    pubSub.publish(Event.ROOM_PLANNER_SHELVES_PREVIEW, preview);
};

export const setShow3DPreview = () => {
    pubSub.publish(Event.ROOM_PLANNER_PREVIEW_TYPE, PreviewType.PREVIEW_3D);
};

export const setShowDoorPreview = () => {
    pubSub.publish(Event.ROOM_PLANNER_PREVIEW_TYPE, PreviewType.PREVIEW_DOOR);
};

export const setShowDrawerPreview = () => {
    pubSub.publish(Event.ROOM_PLANNER_PREVIEW_TYPE, PreviewType.PREVIEW_DRAWER);
};

export const setShowEdgePreview = () => {
    pubSub.publish(Event.ROOM_PLANNER_PREVIEW_TYPE, PreviewType.PREVIEW_EDGE);
};

export const setShowShelvesPreview = () => {
    pubSub.publish(
        Event.ROOM_PLANNER_PREVIEW_TYPE,
        PreviewType.PREVIEW_SHELVES
    );
};

export const setNoPreview = () => {
    pubSub.publish(Event.ROOM_PLANNER_PREVIEW_TYPE, null);
};
