import React, {useCallback, useEffect, useState} from 'react';
import {ButtonContainer} from 'components/customer/RoomPlanner/components/Menu/Header';
import {
    PreviewType,
    useMenuContext,
} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {OverlayTrigger} from 'shared';

export const Preview = () => {
    const {
        preview,
        previewDoor,
        previewEdge,
        previewDrawer,
        previewShelves,
        previewType,
    } = useMenuContext();
    const [searchParams] = useSearchParams();

    const [show, setShow] = useState(false);

    const product = searchParams.get('product');

    const handleClick = useCallback(() => {
        setShow((prev) => !prev);
    }, []);

    useEffect(() => {
        const isEditing = !!searchParams.get('cabinetId');

        setShow(!isEditing);
    }, [searchParams]);

    if (!show) {
        return (
            <div>
                <OverlayTrigger overlay="Open preview">
                    <div>
                        <ToggleIcon
                            onClick={handleClick}
                            iconName="Preview-3d.svg"
                        />
                    </div>
                </OverlayTrigger>
            </div>
        );
    }

    if (!product) return null;

    if (previewType == null) return null;

    if (previewType == PreviewType.PREVIEW_DOOR && !previewDoor) {
        return null;
    }

    if (previewType == PreviewType.PREVIEW_3D && !preview) {
        return null;
    }

    if (previewType == PreviewType.PREVIEW_EDGE && !previewEdge) {
        return null;
    }

    if (previewType == PreviewType.PREVIEW_DRAWER && !previewDrawer) {
        return null;
    }

    if (previewType == PreviewType.PREVIEW_SHELVES && !previewShelves) {
        return null;
    }

    return (
        <PreviewContainer>
            <Header>
                <ButtonContainer onClick={handleClick}>
                    <Icon iconName="Button-Cancel.svg" />
                </ButtonContainer>
            </Header>
            {previewType === PreviewType.PREVIEW_3D ? preview : null}
            {previewType === PreviewType.PREVIEW_DOOR ? previewDoor : null}
            {previewType === PreviewType.PREVIEW_EDGE ? previewEdge : null}
            {previewType === PreviewType.PREVIEW_DRAWER ? previewDrawer : null}
            {previewType === PreviewType.PREVIEW_SHELVES
                ? previewShelves
                : null}
        </PreviewContainer>
    );
};

const ToggleIcon = styled(Icon)`
    cursor: pointer;
    width: 30px;
    height: 30px;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
`;

const PreviewContainer = styled.div`
    max-width: 350px !important;

    position: absolute;
    left: 410px;
    width: 350px;
`;
