import {useDebouncedCallback} from 'use-debounce';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {setIsAutoSaving, getIsAutoSaving} from '../store/roomPlannerSlice';
import {RoomType} from '../types';
import * as THREE from 'three';

const useAutoSave = () => {
    const dispatch = useAppDispatch();
    const isAutoSaving = useAppSelector(getIsAutoSaving);

    const debouncedSave = useDebouncedCallback(
        (
            cabinetGroup: THREE.Group<THREE.Object3DEventMap>,
            roomType?: RoomType
        ) => {
            dispatch(setIsAutoSaving(true));

            localStorage.setItem(
                `cabinet-${roomType}:${
                    (cabinetGroup.userData as {id: string}).id
                }`,
                JSON.stringify(cabinetGroup.position)
            );

            localStorage.setItem(
                `cabinet-rotation:${
                    (cabinetGroup.userData as {id: string}).id
                }`,
                JSON.stringify(cabinetGroup.rotation)
            );

            setTimeout(() => {
                dispatch(setIsAutoSaving(false));
            }, 2000);
        },
        100
    );

    return {isAutoSaving, debouncedSave};
};

export default useAutoSave;
