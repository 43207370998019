import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useAppSelector} from 'store/customer';
import styled from 'styled-components';
import {
    getActiveGroup,
    getActiveGroupPosition,
    getIsDragging,
} from '../../store/roomPlannerSlice';

interface FloatingComponentProps {
    containerRef: React.RefObject<HTMLDivElement>;
    children?: React.ReactNode;
}

const FloatingContainer = styled.div`
    position: absolute;
    background: #1e1e1e; /* Dark mode */
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 36px;
    user-select: none;
    color: #f1f1f1;
`;

const DragHandle = styled.div`
    cursor: grab;
    background: #333; /* Slightly lighter for contrast */
    border-radius: 4px 0 0 4px;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

const IconContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    user-select: none;

    svg {
        width: 18px;
        height: 18px;
        color: #f1f1f1;
        cursor: pointer;
    }

    svg:last-child {
        color: #888;
    }
`;

const FloatingComponent = ({
    containerRef,
    children,
}: FloatingComponentProps) => {
    const floatingRef = useRef<HTMLDivElement>(null);
    const [bounds, setBounds] = useState({width: 0, height: 0});
    const [position, setPosition] = useState({x: 50, y: 50});

    const isDraggingRef = useRef(false);
    const startXRef = useRef(0);
    const startYRef = useRef(0);
    const offsetXRef = useRef(0);
    const offsetYRef = useRef(0);

    const activeGroupPosition = useAppSelector(getActiveGroupPosition);
    const activeGroup = useAppSelector(getActiveGroup);

    const isDragging = useAppSelector(getIsDragging);

    useEffect(() => {
        if (containerRef.current) {
            setBounds({
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight,
            });
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [containerRef]);

    useEffect(() => {
        if (activeGroupPosition && activeGroup) {
            const {screenPos, halfWidth, halfHeight, cabinetHeight} =
                activeGroupPosition;

            // Convert normalized device coordinates (-1 to 1) to screen coordinates
            const x = screenPos.x * halfWidth + halfWidth;
            const y = -screenPos.y * halfHeight + halfHeight; // Y is inverted in screen space

            if (floatingRef.current) {
                const floatingWidth = floatingRef.current.clientWidth;
                const floatingHeight = floatingRef.current.clientHeight;

                setPosition({
                    x: x - floatingWidth / 50, // Align right
                    y: y - floatingHeight - cabinetHeight, // Move slightly above
                });
            }
        }
    }, [activeGroupPosition, activeGroup]);

    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => {
            if (!floatingRef.current) return;
            isDraggingRef.current = true;
            startXRef.current = e.clientX;
            startYRef.current = e.clientY;
            offsetXRef.current = position.x;
            offsetYRef.current = position.y;

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        },
        [position]
    );

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDraggingRef.current || !floatingRef.current) return;
        const deltaX = e.clientX - startXRef.current;
        const deltaY = e.clientY - startYRef.current;

        const newX = Math.max(
            0,
            Math.min(
                offsetXRef.current + deltaX,
                bounds.width - floatingRef.current.clientWidth
            )
        );
        const newY = Math.max(
            0,
            Math.min(
                offsetYRef.current + deltaY,
                bounds.height - floatingRef.current.clientHeight
            )
        );

        setPosition({x: newX, y: newY});
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    if (!activeGroup || isDragging || !activeGroupPosition) return;

    return (
        <FloatingContainer
            ref={floatingRef}
            style={{top: position.y, left: position.x}}>
            <DragHandle onMouseDown={handleMouseDown}>⋮⋮</DragHandle>
            <IconContainer>{children}</IconContainer>
        </FloatingContainer>
    );
};

export default FloatingComponent;
