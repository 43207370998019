import React from 'react';
import styled from 'styled-components';
import {useProductName} from 'components/customer/RoomPlanner/helper/useProductName';
import {Icon} from 'shared/helpers';
import {
    MenuItem,
    useMenuContext,
} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {Breadcrumbs} from 'components/customer/Product';
import {useSearchParams} from 'react-router-dom';
import {Search} from 'shared';

interface HeaderProps {
    showBreadcrumbs?: boolean;
}

export const Header = ({showBreadcrumbs}: HeaderProps) => {
    const {name: productName, productNumber} = useProductName();
    const [searchParams] = useSearchParams();
    const {handleClick} = useMenuContext();

    const productIsSelected = !!searchParams.get('product');

    return (
        <HeaderComponent>
            <ControlsContainer>
                <ProductName>
                    {showBreadcrumbs ? (
                        <div className="search-info">
                            <Breadcrumbs hideLast={true} />
                        </div>
                    ) : null}
                    <strong>
                        {productNumber ? `(${productNumber}) ` : ''}
                        {productName}
                    </strong>
                </ProductName>

                <ButtonContainer onClick={handleClick(MenuItem.ADD_PRODUCT)}>
                    <Icon iconName="Button-Cancel.svg" />
                </ButtonContainer>
            </ControlsContainer>
            {productIsSelected ? null : (
                <div className="productSearch" style={{width: '370px'}}>
                    <label style={{fontSize: '0.8em'}}>Product Search:</label>

                    <div className="productSearchField">
                        <Search isMiniBrowser={true} />
                    </div>
                </div>
            )}
        </HeaderComponent>
    );
};

export const HeaderComponent = styled.div`
    position: absolute;
    top: 0;
    z-index: 99;
    background: white;
`;

export const ProductName = styled.div`
    ${({theme}) => {
        return `
            word-break: break-word;
            text-align: left;

            > strong {
                color: ${theme.colors.primary.main};
                font-size: 0.85em;
            }
        `;
    }}
`;

export const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 370px;
    gap: 5px;
    align-items: flex-start;
`;

export const ButtonContainer = styled.div`
    cursor: pointer;

    > img {
        width: 20px;
        border-radius: 20px;
        border: 1px solid #6d6e71;
    }
`;
