import {useEffect} from 'react';
import {useAppDispatch} from 'store/customer';
import {useJobContext} from 'contexts';
import {useGetRoomPlannerProductsQuery} from 'components/customer/RoomPlanner/store/roomPlannerApi';
import {RoomPlannerProduct} from 'components/customer/RoomPlanner/types';
import {setProducts} from 'components/customer/RoomPlanner/store/roomPlannerSlice';

const useRoomProduct = () => {
    const dispatch = useAppDispatch();
    const {room} = useJobContext() as {room: {id: string}};
    const {data: roomPlannerData} = useGetRoomPlannerProductsQuery(
        {roomId: parseInt(room.id)},
        {
            skip: typeof room.id === 'undefined',
        }
    );

    useEffect(() => {
        if (roomPlannerData) {
            const roomPlannerFinalData: RoomPlannerProduct[] = [];

            roomPlannerData.forEach((product) => {
                const quantity = product.quantity;

                for (let i = 0; i < quantity; i++) {
                    roomPlannerFinalData.push({
                        ...product,
                        duplicate_id:
                            quantity > 1
                                ? `${product.job_cabinet_id}-${i}`
                                : undefined,
                        quantity: 1,
                        // TEMP
                        cabinet_toekick: 0,
                    });
                }
            });

            dispatch(setProducts(roomPlannerFinalData));
        }
    }, [roomPlannerData]);

    useEffect(() => {
        return () => {
            dispatch(setProducts([]));
        };
    }, []);
};

export default useRoomProduct;
