import {useCallback, useRef, useEffect} from 'react';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {shallowEqual} from 'react-redux';
import * as THREE from 'three';
import {
    addArrowsAndText,
    addArrowsAndTextForCabinetDimension,
    addDashedLine,
    createRoomDimensionArrow,
} from 'components/customer/RoomPlanner/helpers/createRoomObjects';
import {
    LShapedDimension,
    RectangularDimension,
} from 'components/customer/RoomPlanner/types';
import {
    getScale,
    getRoomDimensions,
    getRoomType,
    getHiddenWall,
    setRectangularElementDimension,
    getIsTopView,
    getIsEditHeightView,
    setLShapedElementDimension,
    getIsWireframeMode,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import TWEEN from '@tweenjs/tween.js';
import {
    getMeshSizeInPixels,
    addEdgeLines as addEdgeLinesHelper,
} from 'components/customer/RoomPlanner/helpers/mesh';
import ObjectNameMap from '../constants/ObjectNameMap';

const FLOOR_TEXTURE =
    'https://t3.ftcdn.net/jpg/01/63/88/10/360_F_163881016_H2HzI9JD4JbZVPCVMu6X3Vy91HLSVGqd.jpg';

const useRoomLayout = (
    showArrows: boolean,
    dragPosition: THREE.Vector3,
    cabinetGroup: THREE.Group<THREE.Object3DEventMap>,
    getGroupWidth: (group: THREE.Group<THREE.Object3DEventMap>) => {
        width: number;
        height: number;
        depth: number;
    }
) => {
    const dispatch = useAppDispatch();
    const scale = useAppSelector(getScale);
    const arrowRefs = useRef<(THREE.ArrowHelper | THREE.SpriteMaterial)[]>([]);
    const arrowGroupRefs = useRef<THREE.Group<THREE.Object3DEventMap>[]>([]);
    const topViewArrowRefs = useRef<THREE.Group<THREE.Object3DEventMap>[]>([]);
    const leftLeveledStaticArrowRef =
        useRef<THREE.Group<THREE.Object3DEventMap>>(null);
    const lineRefs = useRef<THREE.Line[]>([]);
    const wallRefs = useRef<
        THREE.Mesh<
            THREE.ExtrudeGeometry,
            THREE.MeshStandardMaterial,
            THREE.Object3DEventMap
        >[]
    >([]);
    const floorRef =
        useRef<
            THREE.Mesh<
                THREE.ExtrudeGeometry,
                THREE.MeshStandardMaterial,
                THREE.Object3DEventMap
            >
        >();

    const leftArrowWidth = useRef<number>(55);
    const showArrowsRef = useRef<boolean>(showArrows);

    const {rectangularDimension, lShapedDimension} = useAppSelector(
        getRoomDimensions,
        shallowEqual
    );
    const roomType = useAppSelector(getRoomType);
    const hiddenWall = useAppSelector(getHiddenWall);
    const isTopView = useAppSelector(getIsTopView);
    const isEditHeightView = useAppSelector(getIsEditHeightView);
    const isWireframeMode = useAppSelector(getIsWireframeMode);
    const isWireframeModeRef = useRef<boolean>(isWireframeMode);

    const handleArrows = (
        arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[]
    ) => {
        arrows.forEach((arrow) => {
            arrowRefs.current.push(arrow);
        });
    };

    const handleArrowsAndGroup = (
        arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[],
        arrowGroup?: THREE.Group<THREE.Object3DEventMap>
    ) => {
        arrows.forEach((arrow) => {
            if (!arrowRefs.current.includes(arrow))
                arrowRefs.current.push(arrow);
        });

        if (arrowGroupRefs && !arrowGroupRefs.current.includes(arrowGroup))
            arrowGroupRefs.current.push(arrowGroup);
    };

    const handleLines = (lines: THREE.Line[]) => {
        lines.forEach((line) => {
            lineRefs.current.push(line);
        });
    };

    const createLShapedWalls = useCallback(
        (
            scene: THREE.Scene,
            dimension: LShapedDimension,
            camera: React.MutableRefObject<THREE.Camera | null>,
            renderer: React.MutableRefObject<THREE.WebGLRenderer | null>,
            isLeftLeveledStaticView: boolean
        ) => {
            const leftWidth = dimension.leftWidth * scale - 25;
            const rightWidth = dimension.rightWidth * scale + 60;
            const rightDepth = dimension.rightDepth * scale - 25;
            const leftDepth = dimension.leftDepth * scale + 60;
            const wallHeight = dimension.height * scale + 20;

            const positionY = -(wallHeight / 2) + 60;

            const rightWidthWallMaterial = new THREE.MeshStandardMaterial({
                color: '#fff',
            });

            rightWidthWallMaterial.transparent = true;
            rightWidthWallMaterial.opacity = 1;

            const leftWidthWallMaterial = rightWidthWallMaterial.clone();
            const rightDepthWallMaterial = rightWidthWallMaterial.clone();
            const leftDepthWallMaterial = rightWidthWallMaterial.clone();
            const rightWidth2WallMaterial = rightWidthWallMaterial.clone();
            const leftWidth2WallMaterial = rightWidthWallMaterial.clone();

            const rightWidthShape = new THREE.Shape();
            rightWidthShape.moveTo(0, 0);
            rightWidthShape.lineTo(rightWidth, 0);
            rightWidthShape.lineTo(rightWidth, wallHeight);
            rightWidthShape.lineTo(0, wallHeight);
            rightWidthShape.lineTo(0, 0);

            const leftDepthShape = new THREE.Shape();
            leftDepthShape.moveTo(0, 0);
            leftDepthShape.lineTo(leftDepth, 0);
            leftDepthShape.lineTo(leftDepth, wallHeight);
            leftDepthShape.lineTo(0, wallHeight);
            leftDepthShape.lineTo(0, 0);

            const leftWidthShape = new THREE.Shape();
            leftWidthShape.moveTo(0, 0);
            leftWidthShape.lineTo(leftWidth + 25, 0);
            leftWidthShape.lineTo(leftWidth + 25, wallHeight);
            leftWidthShape.lineTo(0, wallHeight);
            leftWidthShape.lineTo(0, 0);

            const rightDepthWallShape = new THREE.Shape();
            rightDepthWallShape.moveTo(0, 0);
            rightDepthWallShape.lineTo(rightDepth + 25, 0);
            rightDepthWallShape.lineTo(rightDepth + 25, wallHeight);
            rightDepthWallShape.lineTo(0, wallHeight);
            rightDepthWallShape.lineTo(0, 0);

            const rightWidth2Shape = new THREE.Shape();
            rightWidth2Shape.moveTo(0, 0);
            rightWidth2Shape.lineTo(rightWidth - leftDepth, 0);
            rightWidth2Shape.lineTo(rightWidth - leftDepth, wallHeight);
            rightWidth2Shape.lineTo(0, wallHeight);
            rightWidth2Shape.lineTo(0, 0);

            const leftWidth2Shape = new THREE.Shape();
            leftWidth2Shape.moveTo(0, 0);
            leftWidth2Shape.lineTo(leftWidth - rightDepth, 0);
            leftWidth2Shape.lineTo(leftWidth - rightDepth, wallHeight);
            leftWidth2Shape.lineTo(0, wallHeight);
            leftWidth2Shape.lineTo(0, 0);

            // Extrude Settings
            const extrudeSettings = {
                depth: 25,
                bevelEnabled: false,
            };

            const rightWidthGeometry = new THREE.ExtrudeGeometry(
                rightWidthShape,
                extrudeSettings
            );

            const leftDepthGeometry = new THREE.ExtrudeGeometry(
                leftDepthShape,
                extrudeSettings
            );

            const leftWidthGeometry = new THREE.ExtrudeGeometry(
                leftWidthShape,
                extrudeSettings
            );

            const rightDepthWallGeometry = new THREE.ExtrudeGeometry(
                rightDepthWallShape,
                extrudeSettings
            );

            const rightWidth2Geometry = new THREE.ExtrudeGeometry(
                rightWidth2Shape,
                extrudeSettings
            );

            const leftWidth2Geometry = new THREE.ExtrudeGeometry(
                leftWidth2Shape,
                extrudeSettings
            );

            const rightWidthWall = new THREE.Mesh(
                rightWidthGeometry,
                rightWidthWallMaterial
            );
            rightWidthWall.userData.id = 'rightWidthWall';
            rightWidthWall.position.set(
                -(rightWidth / 2),
                positionY,
                -(leftWidth / 2 + 50)
            );

            addArrowsAndText(
                rightWidthWall,
                rightWidth / 2 - 25,
                handleArrows,
                false,
                dimension.rightWidth,
                wallHeight,
                'rightWidthWall'
            );

            // addArrowsAndTextForCabinetDimension(
            //     rightWidthWall,
            //     leftArrowWidth.current,
            //     handleArrowsAndGroup,
            //     false,
            //     true
            // );

            const leftDepthWall = new THREE.Mesh(
                leftDepthGeometry,
                leftDepthWallMaterial
            );
            leftDepthWall.userData.id = 'leftDepthWall';

            leftDepthWall.position.set(
                -rightWidth / 2,
                positionY,
                leftWidth / 2
            );

            addArrowsAndText(
                leftDepthWall,
                leftDepth / 2 - 25,
                handleArrows,
                false,
                dimension.leftDepth,
                wallHeight,
                'leftDepthWall'
            );

            const leftWidthWall = new THREE.Mesh(
                leftWidthGeometry,
                leftWidthWallMaterial
            );
            leftWidthWall.userData.id = 'leftWidthWall';
            leftWidthWall.position.set(
                -rightWidth / 2,
                positionY,
                leftWidth / 2
            );
            leftWidthWall.rotation.y = Math.PI / 2;

            addArrowsAndText(
                leftWidthWall,
                leftWidth / 2,
                handleArrows,
                true,
                dimension.leftWidth,
                wallHeight,
                'leftWidthWall'
            );

            addArrowsAndTextForCabinetDimension(
                leftWidthWall,
                leftArrowWidth.current,
                handleArrowsAndGroup,
                true,
                true
            );

            const rightDepthWall = new THREE.Mesh(
                rightDepthWallGeometry,
                rightDepthWallMaterial
            );
            rightDepthWall.userData.id = 'rightDepthWall';
            rightDepthWall.position.set(
                rightWidth / 2,
                positionY,
                -(leftWidth / 2 + 25)
            );
            rightDepthWall.rotation.y = -Math.PI / 2;

            addArrowsAndText(
                rightDepthWall,
                rightDepth / 2,
                handleArrows,
                true,
                dimension.rightDepth,
                wallHeight,
                'rightDepthWall'
            );

            const rightWidth2Wall = new THREE.Mesh(
                rightWidth2Geometry,
                rightWidth2WallMaterial
            );
            rightWidth2Wall.userData.id = 'rightWidth2Wall';
            rightWidth2Wall.position.set(
                rightWidth - leftDepth - (rightWidth / 2 - leftDepth),
                positionY,
                -(leftWidth / 2 - rightDepth) + 25
            );
            rightWidth2Wall.rotation.y = Math.PI;

            addArrowsAndText(
                rightWidth2Wall,
                (rightWidth - leftDepth) / 2 - 25,
                handleArrows,
                false,
                dimension.rightWidth - dimension.leftDepth,
                wallHeight,
                'rightWidth2Wall'
            );

            const leftWidth2Wall = new THREE.Mesh(
                leftWidth2Geometry,
                leftWidth2WallMaterial
            );
            leftWidth2Wall.userData.id = 'leftWidth2Wall';
            leftWidth2Wall.position.set(
                leftDepth - rightWidth / 2,
                positionY,
                -(leftWidth / 2 - rightDepth)
            );
            leftWidth2Wall.rotation.y = -Math.PI / 2;

            addArrowsAndText(
                leftWidth2Wall,
                (leftWidth - rightDepth) / 2 - 25,
                handleArrows,
                false,
                dimension.leftWidth - dimension.rightDepth,
                wallHeight,
                'leftWidth2Wall'
            );

            const wallGroup = new THREE.Group();
            wallGroup.add(leftWidthWall);
            wallGroup.add(leftWidth2Wall);
            wallGroup.add(rightWidthWall);
            wallGroup.add(rightWidth2Wall);
            wallGroup.add(leftDepthWall);
            wallGroup.add(rightDepthWall);

            wallGroup.position.x = 35;
            wallGroup.name = ObjectNameMap.lShapeWall;
            scene.add(wallGroup);

            wallRefs.current.push(leftWidthWall);
            wallRefs.current.push(leftWidth2Wall);
            wallRefs.current.push(rightWidthWall);
            wallRefs.current.push(rightWidth2Wall);
            wallRefs.current.push(leftDepthWall);
            wallRefs.current.push(rightDepthWall);

            const edgeMaterial = new THREE.LineBasicMaterial({
                color: '#bdc3c7',
            });

            // Create edge lines for each wall
            const addEdgeLines = (mesh: THREE.Mesh, name: string) => {
                const edges = new THREE.EdgesGeometry(mesh.geometry);
                const line = new THREE.LineSegments(edges, edgeMaterial);
                line.name = name;
                mesh.add(line);
            };

            addEdgeLines(leftWidthWall, ObjectNameMap.leftWidthWall);
            addEdgeLines(leftWidth2Wall, ObjectNameMap.leftWidth2Wall);
            addEdgeLines(rightWidthWall, ObjectNameMap.rightWidthWall);
            addEdgeLines(rightWidth2Wall, ObjectNameMap.rightWidth2Wall);
            addEdgeLines(leftDepthWall, ObjectNameMap.leftDepthWall);
            addEdgeLines(rightDepthWall, ObjectNameMap.rightDepthWall);

            const elementWidthInPixels = getMeshSizeInPixels(
                rightWidthWall,
                camera.current,
                renderer.current
            );

            const leftWidthWallClone = leftWidthWall.clone();
            leftWidthWallClone.rotation.y = Math.PI;

            const elementLengthInPixels = getMeshSizeInPixels(
                leftWidthWallClone,
                camera.current,
                renderer.current
            );

            const elementLeftDepthInPixels = getMeshSizeInPixels(
                leftDepthWall,
                camera.current,
                renderer.current
            );

            const rightDepthWallClone = rightDepthWall.clone();
            rightDepthWallClone.rotation.y = Math.PI;

            const elementRightDepthInPixels = getMeshSizeInPixels(
                rightDepthWallClone,
                camera.current,
                renderer.current
            );

            dispatch(
                setLShapedElementDimension({
                    rightWidth: elementWidthInPixels.width,
                    leftWidth: elementLengthInPixels.width,
                    rightDepth: elementRightDepthInPixels.width,
                    leftDepth: elementLeftDepthInPixels.width,
                    height: elementWidthInPixels.height,
                })
            );

            const animationSpeed = 200;

            if (isLeftLeveledStaticView) {
                leftWidthWallMaterial.opacity = 0;
            } else {
                leftWidthWallMaterial.opacity = 1;
            }

            handleWallTransition(
                leftWidthWall,
                leftWidthWallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'leftWidthWall'
                ),
                edgeMaterial
            );
            handleWallTransition(
                leftWidth2Wall,
                leftWidth2WallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'leftWidth2Wall'
                ),
                edgeMaterial
            );
            handleWallTransition(
                rightWidthWall,
                rightWidthWallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'rightWidthWall'
                ),
                edgeMaterial
            );
            handleWallTransition(
                rightWidth2Wall,
                rightWidth2WallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'rightWidth2Wall'
                ),
                edgeMaterial
            );
            handleWallTransition(
                leftDepthWall,
                leftDepthWallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'leftDepthWall'
                ),
                edgeMaterial
            );
            handleWallTransition(
                rightDepthWall,
                rightDepthWallMaterial,
                animationSpeed,
                arrowRefs.current.filter(
                    (arrow) => arrow.userData.id === 'rightDepthWall'
                ),
                edgeMaterial
            );
        },
        [scale]
    );

    const createLShapedFloor = (
        scene: THREE.Scene,
        textureLoader: THREE.TextureLoader,
        dimension: LShapedDimension,
        isTopView: boolean,
        isLeftLeveledStaticView: boolean
    ) => {
        const leftWidth = dimension.leftWidth * scale - 25;
        const rightWidth = dimension.rightWidth * scale + 60;
        const rightDepth = dimension.rightDepth * scale - 25;
        const leftDepth = dimension.leftDepth * scale + 60;
        const wallHeight = dimension.height * scale + 20;

        const positionY = -(wallHeight / 2) + 60;

        const floorTexture = textureLoader.load(FLOOR_TEXTURE);
        floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
        floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
        floorTexture.offset.set(0, 0.5);
        floorTexture.repeat.set(0.001, 0.001);

        const floorMaterial = new THREE.MeshStandardMaterial({
            map: floorTexture,
            transparent: true,
            opacity: 1,
        });

        const floorShape = new THREE.Shape();
        floorShape.moveTo(0, 0);
        floorShape.lineTo(leftDepth - 50, 0);
        floorShape.lineTo(leftDepth - 50, leftWidth - rightDepth);
        floorShape.lineTo(rightWidth - 50, leftWidth - rightDepth);
        floorShape.lineTo(rightWidth - 50, leftWidth + 25);
        floorShape.lineTo(0, leftWidth + 25);
        floorShape.lineTo(0, 0);
        floorShape.closePath();

        const extrudeSettings = {
            depth: 1,
            bevelEnabled: false,
        };

        const floorGeometry = new THREE.ExtrudeGeometry(
            floorShape,
            extrudeSettings
        );
        const floor = new THREE.Mesh(floorGeometry, floorMaterial);
        floor.rotation.x = -Math.PI / 2;
        floor.position.y = positionY;
        floor.position.z = leftWidth / 2;
        floor.position.x = -(rightWidth / 2) + 60;

        // Add arrows for top and left-leveled static views

        const topArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(rightWidth / 2) + 60,
                positionY + wallHeight,
                -leftWidth / 2 - 15
            ),
            arrowLength: rightWidth - 50,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        const leftArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(leftWidth / 2) + 10,
                positionY + wallHeight,
                -rightWidth / 2 + 32
            ),
            arrowLength: leftWidth,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        leftArrowGroup.rotation.y = Math.PI / 2;
        leftArrowGroup.position.x = 35;

        const rightDepthArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(leftWidth / 2) + leftWidth - rightDepth + 10,
                positionY + wallHeight,
                rightWidth / 2 - 32
            ),
            arrowLength: rightDepth,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        rightDepthArrowGroup.rotation.y = Math.PI / 2;
        rightDepthArrowGroup.position.x = 35;

        const leftDepthArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(rightWidth / 2) + 67,
                positionY + wallHeight,
                leftWidth / 2 - 10
            ),
            arrowLength: leftDepth - 60,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        scene.add(topArrowGroup);
        scene.add(leftArrowGroup);
        scene.add(rightDepthArrowGroup);
        scene.add(leftDepthArrowGroup);
        topArrowGroup.visible = isTopView;
        leftArrowGroup.visible = isTopView;
        rightDepthArrowGroup.visible = isTopView;
        leftDepthArrowGroup.visible = isTopView;
        topViewArrowRefs.current.push(topArrowGroup);
        topViewArrowRefs.current.push(leftArrowGroup);
        topViewArrowRefs.current.push(rightDepthArrowGroup);
        topViewArrowRefs.current.push(leftDepthArrowGroup);

        const verticalArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(0, 1, 0),
            reverseArrowDir: new THREE.Vector3(0, -1, 0),
            arrowOrigin: new THREE.Vector3(
                leftWidth / 2 + 15,
                positionY,
                -rightWidth / 2
            ),
            arrowLength: wallHeight,
            isVertical: true,
        });

        verticalArrowGroup.rotation.y = Math.PI / 2;
        scene.add(verticalArrowGroup);
        verticalArrowGroup.visible = isLeftLeveledStaticView;
        leftLeveledStaticArrowRef.current = verticalArrowGroup;

        const {edgeMaterial} = addEdgeLinesHelper(floor, ObjectNameMap.floor);
        floor.userData.onToggleWireframe = (isWireframe: boolean) => {
            edgeMaterial.opacity = isWireframe ? 1 : 0;
            floorMaterial.opacity = isWireframe ? 0 : 1;
        };

        floorRef.current = floor;

        addDashedLine(
            floor,
            handleLines,
            lShapedDimension.rightWidth * scale,
            lShapedDimension.leftWidth * scale
        );
        floor.name = ObjectNameMap.lshapeFloor;
        scene.add(floor);
    };

    const createRectangularFloor = (
        scene: THREE.Scene,
        textureLoader: THREE.TextureLoader,
        dimension: RectangularDimension,
        isTopView: boolean,
        isLeftLeveledStaticView: boolean
    ) => {
        const length = dimension.length * scale - 25;
        const width = dimension.width * scale + 60;
        const wallHeight = dimension.height * scale;
        const positionY = -(wallHeight / 2) + 50;

        const floorTexture = textureLoader.load(FLOOR_TEXTURE);
        floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
        floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
        floorTexture.offset.set(0, 0.5);
        floorTexture.repeat.set(0.001, 0.001);

        const floorMaterial = new THREE.MeshStandardMaterial({
            map: floorTexture,
            transparent: true,
            opacity: 1,
        });

        const floorShape = new THREE.Shape();
        floorShape.moveTo(0, 0);
        floorShape.lineTo(0, length + 25);
        floorShape.lineTo(width - 50, length + 25);
        floorShape.lineTo(width - 50, 0);
        floorShape.closePath();

        const extrudeSettings = {
            depth: 1,
            bevelEnabled: false,
        };

        const floorGeometry = new THREE.ExtrudeGeometry(
            floorShape,
            extrudeSettings
        );
        const floor = new THREE.Mesh(floorGeometry, floorMaterial);
        floor.rotation.x = -Math.PI / 2;
        floor.position.y = positionY;
        floor.position.x = -(width / 2) + 60;
        floor.position.z = length / 2;

        // Add arrows for top and left-leveled static views

        const topArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(width / 2) + 60,
                positionY + wallHeight,
                -length / 2 - 15
            ),
            arrowLength: width - 50,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        const leftArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(1, 0, 0),
            reverseArrowDir: new THREE.Vector3(-1, 0, 0),
            arrowOrigin: new THREE.Vector3(
                -(length / 2) + 10,
                positionY + wallHeight,
                -width / 2 + 32
            ),
            arrowLength: length,
            onArrowsCreated: (arrow, reverseArrow) => {
                arrow.rotation.x = -Math.PI / 2;
                reverseArrow.rotation.x = -Math.PI / 2;
            },
        });

        leftArrowGroup.rotation.y = Math.PI / 2;
        leftArrowGroup.position.x = 35;

        scene.add(topArrowGroup);
        scene.add(leftArrowGroup);
        topArrowGroup.visible = isTopView;
        leftArrowGroup.visible = isTopView;
        topViewArrowRefs.current.push(topArrowGroup);
        topViewArrowRefs.current.push(leftArrowGroup);

        const verticalArrowGroup = createRoomDimensionArrow({
            arrowDir: new THREE.Vector3(0, 1, 0),
            reverseArrowDir: new THREE.Vector3(0, -1, 0),
            arrowOrigin: new THREE.Vector3(
                length / 2 + 15,
                positionY,
                -width / 2
            ),
            arrowLength: wallHeight + 20,
            isVertical: true,
        });

        verticalArrowGroup.rotation.y = Math.PI / 2;
        scene.add(verticalArrowGroup);
        verticalArrowGroup.visible = isLeftLeveledStaticView;
        leftLeveledStaticArrowRef.current = verticalArrowGroup;

        floor.name = ObjectNameMap.floor;
        const {edgeMaterial} = addEdgeLinesHelper(floor, ObjectNameMap.floor);
        scene.add(floor);

        floor.userData.onToggleWireframe = (isWireframe: boolean) => {
            edgeMaterial.opacity = isWireframe ? 1 : 0;
            floorMaterial.opacity = isWireframe ? 0 : 1;
        };

        floorRef.current = floor;

        addDashedLine(
            floor,
            handleLines,
            rectangularDimension.width * scale,
            rectangularDimension.length * scale
        );
    };

    const createRectangularWalls = (
        scene: THREE.Scene,
        dimension: RectangularDimension,
        camera: React.MutableRefObject<THREE.Camera | null>,
        renderer: React.MutableRefObject<THREE.WebGLRenderer | null>,
        isLeftLeveledStaticView: boolean
    ) => {
        const length = dimension.length * scale - 25;
        const width = dimension.width * scale + 60;
        const wallHeight = dimension.height * scale + 20;
        const positionY = -(wallHeight / 2) + 60;

        const backWallMaterial = new THREE.MeshStandardMaterial({
            color: '#fff',
        });

        backWallMaterial.transparent = true;
        backWallMaterial.opacity = 1;

        const frontWallMaterial = backWallMaterial.clone();
        const leftWallMaterial = backWallMaterial.clone();
        const rightWallMaterial = backWallMaterial.clone();

        const wallShape = new THREE.Shape();
        wallShape.moveTo(0, 0);
        wallShape.lineTo(width - 25, 0);
        wallShape.lineTo(width - 25, wallHeight);
        wallShape.lineTo(0, wallHeight);
        wallShape.lineTo(0, 0);

        const leftWallShape = new THREE.Shape();
        leftWallShape.moveTo(0, 0);
        leftWallShape.lineTo(length + 25, 0);
        leftWallShape.lineTo(length + 25, wallHeight);
        leftWallShape.lineTo(0, wallHeight);
        leftWallShape.lineTo(0, 0);

        const rightWallShape = new THREE.Shape();
        rightWallShape.moveTo(0, 0);
        rightWallShape.lineTo(length + 75, 0);
        rightWallShape.lineTo(length + 75, wallHeight);
        rightWallShape.lineTo(0, wallHeight);
        rightWallShape.lineTo(0, 0);

        const extrudeSettings = {
            depth: 25,
            bevelEnabled: false,
        };

        const wallGeometry = new THREE.ExtrudeGeometry(
            wallShape,
            extrudeSettings
        );

        const leftWallGeometry = new THREE.ExtrudeGeometry(
            leftWallShape,
            extrudeSettings
        );

        const rightWallGeometry = new THREE.ExtrudeGeometry(
            rightWallShape,
            extrudeSettings
        );

        const backWall = new THREE.Mesh(wallGeometry, backWallMaterial);
        backWall.position.set(width / 2 - 25, positionY, -length / 2 - 25);
        backWall.rotation.y = Math.PI;
        backWall.userData.id = 'backWall';
        // scene.add(backWall);
        addArrowsAndText(
            backWall,
            width / 2 - 25,
            handleArrows,
            false,
            dimension.width,
            wallHeight,
            'backWall'
        );

        addArrowsAndTextForCabinetDimension(
            backWall,
            leftArrowWidth.current,
            handleArrowsAndGroup
        );

        const frontWall = new THREE.Mesh(wallGeometry, frontWallMaterial);
        frontWall.position.set(width / 2 - 25, positionY, length / 2 + 25);
        frontWall.rotation.y = Math.PI;
        frontWall.userData.id = 'frontWall';
        // scene.add(frontWall);
        addArrowsAndText(
            frontWall,
            width / 2 - 25,
            handleArrows,
            false,
            dimension.width,
            wallHeight,
            'frontWall'
        );

        const leftWall = new THREE.Mesh(leftWallGeometry, leftWallMaterial);
        leftWall.position.set(-width / 2, positionY, length / 2);
        leftWall.rotation.y = Math.PI / 2;
        leftWall.userData.id = 'leftWall';
        // scene.add(leftWall);
        addArrowsAndText(
            leftWall,
            length / 2 + 25,
            handleArrows,
            true,
            dimension.length,
            wallHeight,
            'leftWall'
        );

        addArrowsAndTextForCabinetDimension(
            leftWall,
            leftArrowWidth.current,
            handleArrowsAndGroup,
            true
        );

        const rightWall = new THREE.Mesh(rightWallGeometry, rightWallMaterial);
        rightWall.position.set(width / 2 - 25, positionY, length / 2 + 25);
        rightWall.rotation.y = Math.PI / 2;
        rightWall.userData.id = 'rightWall';
        // scene.add(rightWall);
        addArrowsAndText(
            rightWall,
            length / 2 + 25,
            handleArrows,
            true,
            dimension.length,
            wallHeight,
            'rightWall'
        );

        const edgeMaterial = new THREE.LineBasicMaterial({color: '#bdc3c7'});

        // Create edge lines for each wall
        const addEdgeLines = (mesh: THREE.Mesh, name: string) => {
            const edges = new THREE.EdgesGeometry(mesh.geometry);
            const line = new THREE.LineSegments(edges, edgeMaterial);
            line.name = name;
            mesh.add(line);
        };

        addEdgeLines(backWall, ObjectNameMap.wallEdgeBack);
        addEdgeLines(frontWall, ObjectNameMap.wallEdgeFront);
        addEdgeLines(leftWall, ObjectNameMap.wallEdgeLeft);
        addEdgeLines(rightWall, ObjectNameMap.wallEdgeRight);

        backWall.name = 'wall:back';
        frontWall.name = 'wall:front';
        leftWall.name = 'wall:left';
        rightWall.name = 'wall:right';

        wallRefs.current.push(leftWall);
        wallRefs.current.push(rightWall);
        wallRefs.current.push(backWall);
        wallRefs.current.push(frontWall);

        const elementWidthInPixels = getMeshSizeInPixels(
            backWall,
            camera.current,
            renderer.current
        );

        const leftWallClone = leftWall.clone();
        leftWallClone.rotation.y = Math.PI;

        const elementLengthInPixels = getMeshSizeInPixels(
            leftWallClone,
            camera.current,
            renderer.current
        );

        dispatch(
            setRectangularElementDimension({
                width: elementWidthInPixels.width,
                length: elementLengthInPixels.width,
                height: elementWidthInPixels.height,
            })
        );

        if (isLeftLeveledStaticView) {
            leftWallMaterial.opacity = 0;
            rightWallMaterial.opacity = 1;
        } else {
            frontWallMaterial.opacity = 0;
            rightWallMaterial.opacity = 0;
        }

        const animationSpeed = 200;

        const wallGroup = new THREE.Group();
        wallGroup.add(backWall);
        wallGroup.add(frontWall);
        wallGroup.add(leftWall);
        wallGroup.add(rightWall);

        wallGroup.position.x = 35;
        wallGroup.name = ObjectNameMap.rectangularWall;
        scene.add(wallGroup);

        handleWallTransition(
            backWall,
            backWallMaterial,
            animationSpeed,
            arrowRefs.current.filter(
                (arrow) => arrow.userData.id === 'backWall'
            ),
            edgeMaterial
        );
        handleWallTransition(
            frontWall,
            frontWallMaterial,
            animationSpeed,
            arrowRefs.current.filter(
                (arrow) => arrow.userData.id === 'frontWall'
            ),
            edgeMaterial
        );
        handleWallTransition(
            leftWall,
            leftWallMaterial,
            animationSpeed,
            arrowRefs.current.filter(
                (arrow) => arrow.userData.id === 'leftWall'
            ),
            edgeMaterial
        );
        handleWallTransition(
            rightWall,
            rightWallMaterial,
            animationSpeed,
            arrowRefs.current.filter(
                (arrow) => arrow.userData.id === 'rightWall'
            ),
            edgeMaterial
        );
    };

    const isNameMatchToWall = (name: string) => {
        return (
            name === ObjectNameMap.wallEdgeBack ||
            name === ObjectNameMap.wallEdgeFront ||
            name === ObjectNameMap.wallEdgeLeft ||
            name === ObjectNameMap.wallEdgeRight ||
            name === ObjectNameMap.leftWidthWall ||
            name === ObjectNameMap.leftWidth2Wall ||
            name === ObjectNameMap.rightWidthWall ||
            name === ObjectNameMap.rightWidth2Wall ||
            name === ObjectNameMap.leftDepthWall ||
            name === ObjectNameMap.rightDepthWall
        );
    };

    const handleWallTransition = (
        wall: THREE.Mesh<
            THREE.ExtrudeGeometry,
            THREE.MeshStandardMaterial,
            THREE.Object3DEventMap
        >,
        material: THREE.MeshStandardMaterial,
        animationSpeed: number,
        arrows?: (THREE.ArrowHelper | THREE.SpriteMaterial)[],
        lineMaterial?: THREE.LineBasicMaterial
    ) => {
        wall.userData.onToggleWireframe = (isWireframe: boolean) => {
            wall.traverse((ch) => {
                if (isNameMatchToWall(ch.name)) {
                    material.opacity = isWireframe ? 0 : ch.visible ? 1 : 0;
                }
            });

            if (lineMaterial) {
                lineMaterial.color.set(isWireframe ? '#000' : '#bdc3c7');
            }
        };

        wall.userData.onHide = () => {
            new TWEEN.Tween(material)
                .to({opacity: 0}, animationSpeed)
                .easing(TWEEN.Easing.Cubic.Out)
                .onUpdate(() => {
                    wall.traverse((ch) => {
                        if (isNameMatchToWall(ch.name)) {
                            ch.visible = false;
                        }
                    });
                })
                .start();

            arrows?.forEach((arrow) => {
                if (showArrowsRef.current) {
                    arrow.visible = false;
                }
            });
        };

        wall.userData.onShow = () => {
            const isWireframe = isWireframeModeRef.current;

            new TWEEN.Tween(material)
                .to({opacity: isWireframe ? 0 : 1}, animationSpeed)
                .easing(TWEEN.Easing.Cubic.Out)
                .onUpdate(() => {
                    wall.traverse((ch) => {
                        if (isNameMatchToWall(ch.name)) {
                            ch.visible = true;
                        }
                    });
                })
                .start();

            arrows?.forEach((arrow) => {
                if (showArrowsRef.current) {
                    arrow.visible = true;
                }
            });
        };
    };

    useEffect(() => {
        showArrowsRef.current = showArrows;

        if (arrowRefs.current && roomType) {
            arrowRefs.current.forEach((arrow) => {
                arrow.visible = showArrows;

                if (showArrows) {
                    let hiddenWalls: string[] = [];

                    if (roomType === 'RECTANGULAR') {
                        switch (hiddenWall) {
                            case 'backWall':
                                hiddenWalls = ['backWall', 'leftWall'];
                                break;
                            case 'frontWall':
                                hiddenWalls = ['frontWall', 'rightWall'];
                                break;
                            case 'leftWall':
                                hiddenWalls = ['leftWall', 'frontWall'];
                                break;
                            default:
                                hiddenWalls = ['rightWall', 'backWall'];
                                break;
                        }
                    }

                    if (roomType === 'LSHAPED') {
                        switch (hiddenWall) {
                            case 'leftWall':
                                hiddenWalls = [
                                    'leftDepthWall',
                                    'leftWidthWall',
                                ];
                                break;
                            case 'frontWall':
                                hiddenWalls = [
                                    'leftDepthWall',
                                    'leftWidth2Wall',
                                    'rightWidth2Wall',
                                ];
                                break;
                            case 'rightWall':
                                hiddenWalls = [
                                    'leftWidth2Wall',
                                    'rightWidth2Wall',
                                    'rightDepthWall',
                                ];
                                break;
                            default:
                                hiddenWalls = [
                                    'rightWidthWall',
                                    'rightDepthWall',
                                ];
                                break;
                        }
                    }

                    arrow.visible = !hiddenWalls.includes(
                        arrow.userData.id as string
                    );
                }
            });
        }
    }, [showArrows, hiddenWall, roomType]);

    useEffect(() => {
        if (!cabinetGroup) return;

        const {depth, width} = getGroupWidth(cabinetGroup);
        const roomLength =
            roomType === 'RECTANGULAR'
                ? rectangularDimension.length
                : lShapedDimension.leftWidth;
        const roomWidth =
            roomType === 'RECTANGULAR'
                ? rectangularDimension.width
                : lShapedDimension.rightWidth;

        if (lineRefs.current) {
            lineRefs.current.forEach((line) => {
                line.visible = showArrows;

                if (showArrows) {
                    const leftLinePosition =
                        -cabinetGroup?.position.z +
                        depth +
                        (roomLength * scale) / 2 -
                        305;
                    const rightLinePosition =
                        cabinetGroup?.position.x +
                        (roomWidth * scale) / 2 +
                        275;

                    if (line.userData.id == 'leftLine')
                        line.position.y = leftLinePosition;
                    if (line.userData.id == 'leftLine2')
                        line.position.y = leftLinePosition - depth;
                    if (line.userData.id == 'rightLine')
                        line.position.x = rightLinePosition;
                    if (line.userData.id == 'rightLine2')
                        line.position.x = rightLinePosition + width;
                }
            });
        }

        if (arrowGroupRefs.current) {
            arrowGroupRefs.current.forEach((arrow) => {
                if (showArrows) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    arrow.userData.onDrag(width, depth);

                    if (arrow.userData.id == 'arrowGroupTop') {
                        arrow.position.z = -50;

                        if (roomType === 'RECTANGULAR')
                            arrow.position.x =
                                -cabinetGroup?.position.x +
                                (roomWidth * scale) / 2 -
                                218;
                        else {
                            arrow.position.x =
                                cabinetGroup?.position.x +
                                (lShapedDimension.rightWidth * scale) / 2;
                            arrow.position.z = 0;
                        }
                    }
                    if (arrow.userData.id == 'arrowGroupLeft')
                        arrow.position.x =
                            -cabinetGroup?.position.z +
                            (roomLength * scale) / 2;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    arrow.userData.onDragEnd();
                }
            });
        }
    }, [
        dragPosition,
        showArrows,
        cabinetGroup,
        rectangularDimension,
        lShapedDimension,
        roomType,
    ]);

    useEffect(() => {
        if (isTopView) {
            topViewArrowRefs.current.forEach((arrow) => {
                arrow.visible = true;
            });
        } else {
            topViewArrowRefs.current.forEach((arrow) => {
                arrow.visible = false;
            });
        }
    }, [isTopView]);

    useEffect(() => {
        if (leftLeveledStaticArrowRef.current) {
            if (isEditHeightView) {
                leftLeveledStaticArrowRef.current.visible = true;
            } else {
                leftLeveledStaticArrowRef.current.visible = false;
            }
        }
    }, [isEditHeightView]);

    useEffect(() => {
        isWireframeModeRef.current = isWireframeMode;

        if (floorRef.current) {
            (
                floorRef.current.userData as {
                    onToggleWireframe: (isWireframe: boolean) => void;
                }
            ).onToggleWireframe(isWireframeMode);
        }
    }, [isWireframeMode]);

    return {
        createLShapedWalls,
        createLShapedFloor,
        createRectangularFloor,
        createRectangularWalls,
        wallRefs,
    };
};

export default useRoomLayout;
