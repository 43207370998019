import React, {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {CBCButton, getColoredImage} from 'shared/helpers';
import {Modal, Image} from 'react-bootstrap';
import styled, {useTheme} from 'styled-components';
import {LShapedRoom, RectangularRoom} from 'assets';
import {RoomType} from 'components/customer/RoomPlanner/types';
import {
    setRoomType as setRoomTypeAction,
    getRoomDimensions,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import Footer, {
    CloseEventType,
} from 'shared/components/ConfirmationDialog/Footer';
import {useFormik} from 'formik';
// import * as Yup from 'yup';

// const validationSchema = Yup.object({
//     length: Yup.number()
//         .typeError('Must be a number')
//         .min(1000, 'Minimum value is 1000')
//         .max(20000, 'Maximum value is 20000')
//         .required('Required'),
// });

interface RoomLayoutModalProps {
    onClose?: () => void;
    onCancel?: () => void;
    className?: string;
    open: boolean;
    setModalOpen: (show: boolean) => void;
}

const RoomLayoutModal = ({open, setModalOpen}: RoomLayoutModalProps) => {
    const dispatch = useAppDispatch();
    const {rectangularDimension, lShapedDimension} = useAppSelector(
        getRoomDimensions,
        shallowEqual
    );
    const [roomType, setRoomType] = useState<RoomType>('RECTANGULAR');
    const theme = useTheme();
    const primaryColour = theme.colors.primary.main;

    const formik = useFormik({
        initialValues: {
            ...rectangularDimension,
            ...lShapedDimension,
        },
        enableReinitialize: true,
        // validationSchema,
        onSubmit: () => {
            dispatch(setRoomTypeAction(roomType));
            setModalOpen(false);
        },
    });

    const handleFooterEvents = useCallback(
        (event: CloseEventType) => {
            if (event == CloseEventType.APPLY) {
                void formik.submitForm();
            }
            if (event == CloseEventType.CANCEL) {
                window.history.back();
            }
        },
        [roomType]
    );

    const handleSelectRoomType = useCallback(
        (roomType: RoomType) => () => {
            setRoomType(roomType);
        },
        []
    );

    return (
        <RoomLayoutStyledModal show={open} animation={false} backdrop="static">
            <Header>
                <div>Select Room Layout Shape</div>
            </Header>
            <Body>
                <Container>
                    <div style={{display: 'flex'}}>
                        <ImageCard
                            onClick={handleSelectRoomType('RECTANGULAR')}
                            $active={roomType === 'RECTANGULAR'}>
                            <StyledImageAction
                                className="icon_button"
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                                src={getColoredImage(
                                    RectangularRoom,
                                    '#204480',
                                    primaryColour
                                )}
                            />
                            <ImageTitle>Rectangular Room</ImageTitle>
                        </ImageCard>

                        <ImageCard
                            onClick={handleSelectRoomType('LSHAPED')}
                            $active={roomType === 'LSHAPED'}>
                            <StyledImageAction
                                className="icon_button"
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                                src={getColoredImage(
                                    LShapedRoom,
                                    '#204480',
                                    primaryColour
                                )}
                            />
                            <ImageTitle>L-shaped Room</ImageTitle>
                        </ImageCard>
                    </div>
                </Container>
            </Body>
            <Footer
                yesButtonText="Create Room"
                noButtonText="Go Back"
                onClose={handleFooterEvents}
            />
        </RoomLayoutStyledModal>
    );
};

const RoomLayoutStyledModal = styled(Modal)`
    background: rgba(255, 255, 255, 0.5);

    .modal-dialog {
        width: 30vw;
        max-width: initial;
        // height: calc(100% - 300px);
        margin: 100px auto;
    }

    .modal-content {
        border-radius: 20px;
        box-shadow: 0px 0px 10px 1px #616161bf;
        border: 0;
        height: 100%;
    }

    @media screen and (max-height: 768px) and (orientation: landscape),
        screen and (max-width: 1367px) and (orientation: landscape) {
        .modal-dialog {
            width: 70vw;
            margin: 0;
            height: calc(100% - 50px);
            margin: 25px auto;
        }
    }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
        .modal-dialog {
            width: 90vw;
            height: calc(100% - 300px);
            margin: 150px auto;
        }
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        overflow-y: hidden !important;

        .modal-dialog {
            width: 100vw;
            height: 100vh;
            margin: 0;
        }

        .modal-content {
            border-radius: 0 !important;
            height: 100%;
        }
    }
`;

export const Header = styled(Modal.Header)`
    background: rgb(var(--primary_colour));
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px;

    > div {
        text-align: center;
        width: 100%;
        font-weight: 900;
        color: white;
        font-size: 1em;
    }

    .close {
        display: none;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        border-radius: 0 !important;
    }
`;

const Container = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        padding: 10px;
    }
`;

const Body = styled(Modal.Body)`
    padding: 0;
    display: flex;
    height: calc(100% - 45px - 71px);
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
export const CloseButton = styled((props) => <CBCButton {...props} />)`
    color: rgb(138, 128, 128);
    background: transparent;
    border: 0 !important;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    img {
        width: 20px !important;
        margin: 0 !important;
    }
`;

const ImageCard = styled.div<{$active?: boolean}>`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 15px;
    margin: 8px;
    flex: 1;
    border-radius: 8px;
    cursor: pointer;
    background: ${({$active}) => ($active ? '#E8F5E9' : '')};
`;

const StyledImageAction = styled(Image)`
    max-height: 130px;
    border: 2px solid transparent;
    margin: 2px;
`;

const ImageTitle = styled.p`
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    margin-top: 5px;
    text-align: center;
`;

export default RoomLayoutModal;
