import React from 'react';
import {MenuProvider} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {
    MenuComponent,
    MenuProps,
} from 'components/customer/RoomPlanner/components/Menu/MenuComponent';

export const Menu = (props: MenuProps) => {
    return (
        <MenuProvider>
            <MenuComponent {...props} />
        </MenuProvider>
    );
};
