const ObjectNameMap = {
    floor: '3D:room:floor:rectangular',
    lshapeFloor: '3D:room:floor:lshape',
    rectangularWall: '3d:room:wall:rectangular',
    lShapeWall: '3d:room:wall:lshape',

    wallEdgeLeft: '3D:room:wall:edge:left',
    wallEdgeRight: '3D:room:wall:edge:right',
    wallEdgeFront: '3D:room:wall:edge:front',
    wallEdgeBack: '3D:room:wall:edge:back',

    leftWidthWall: '3D:room:wall:edge:leftWidthWall',
    leftWidth2Wall: '3D:room:wall:edge:leftWidth2Wall',
    rightWidthWall: '3D:room:wall:edge:rightWidthWall',
    rightWidth2Wall: '3D:room:wall:edge:rightWidth2Wall',
    leftDepthWall: '3D:room:wall:edge:leftDepthWall',
    rightDepthWall: '3D:room:wall:edge:rightDepthWall',
};

export default ObjectNameMap;
